import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import "../dataResults/dataresults.scss";
import {  TrashIcon } from "../../assets/SVG/svg";
import Modal from "react-bootstrap/Modal";
import { useMutation } from "@tanstack/react-query";
import { editLineitem, deleteLineitem } from "../../services/api";
import { useTranslation } from "react-i18next";

export const LineItems = ({lineitems,onClose,batch,alert,updateLineItemValue,row,idinv,removeLineItem,edittable}) => {
  const patchLine = useMutation(editLineitem);
  const dellLine = useMutation(deleteLineitem);
  const [columns, setColumns] = useState(
    lineitems[0] ? Object?.keys(lineitems[0]) : []
  );
  const filteredColumns = columns?.filter(column => column !== 'id');

  // line items column names like invoice id, 
  //console.log("filtered columns = ", filteredColumns)

  // function columnNames(col){
  //   if (localStorage.getItem("i18nextLng") === "ar"){

  //     if (col === "invoice id"){
  //       return t("invoice_id")
  //     }
  //     else if (col === "amount"){
  //       return t("amount")
  //     }
  //     else if (col === "description"){
  //       return t("description")
  //     }
  //     else if (col === "tax amount"){
  //       return t("tax_amount")
  //     }
  //     else if (col === "invoice id"){
  //       return t("invoice_id")
  //     }
      
  //   }
  //   else return col
  // }

  const editlineitemCall = (colId, newValue, id) => {
    patchLine.mutate(
      {
        val: { [colId]: newValue },
        profile: localStorage.getItem("profileid"),
        batch: idinv,
        id: id,
      },

      {
        onSuccess: (data, variables, context) => {},
        onError: (error, variables, context) => {},
      }
    );
  };
  const dellInvoiceCall = (id,rowIndex,row) => {
    dellLine.mutate(
      {
        profile: localStorage.getItem("profileid"),
        batch: idinv,
        id:id
      },

      {
        onSuccess: (dataa, variables, context) => {
          removeLineItem(rowIndex,row);

        },
        onError: (error, variables, context) => {},
      }
    );
  };

  const {t} = useTranslation()

  
  return (
    <Modal
      // className="modal-custom"
      centered
      show={true}
      onHide={onClose}
      aria-labelledby="example-modal-sizes-title-sm"
      size="lg"
      className="line-item-modal"
    >
      {/* <span className="modal-close-btn" onClick={() => props?.onClose}>
      <ModalCloseIcon />
    </span> */}
      <Modal.Header closeButton>
        <Modal.Title style={{width:"92%"}}> {t("line_items")} </Modal.Title>
      </Modal.Header>

      <Modal.Body>
       {(!Array.isArray(lineitems) || lineitems.length === 0)?
     <div>{t("no_data")}</div>
  :<Table responsive className="data-table" bordered>
          <thead>
            <tr>
              {filteredColumns?.map((column, index) => (

                // <th key={index}>{column?.replace(/_/g, ' ')}</th>

                <th key={index}>{t(column)}</th>
              ))}
             {!alert && ( <th></th>)}
            </tr>
          </thead>
          <tbody>
            {lineitems.map((item, rowIndex) => (
              <tr key={item?.id}>
                {filteredColumns?.map((column, colIndex) => (
                  <td key={colIndex}>
                    <input
                      className={
                        item[column].value === "Pass"
                          ? "form-control table-form-control pass-event-key"
                          : item[column].value === "Fail"
                          ? "form-control table-form-control fail-event-key"
                          : "form-control table-form-control"
                      }
                      type="text"
                      value={item[column].value}
                      onChange={(e) => {
                        updateLineItemValue(
                          e.target.value,
                          rowIndex,
                          row,
                          column
                        );
                        editlineitemCall(
                          column,
                          e.target.value,
                          item?.id?.value
                        );
                      }}
                      readOnly={!edittable}
                    />
                  </td>
                ))}
                {!alert && (
                  <td
                    className="sticky-column-right"
                    onClick={() => {
                      if (!alert) {
                        dellInvoiceCall(item?.id?.value,rowIndex,row)
                      }
                    }}
                  >
                    <TrashIcon />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>}
      </Modal.Body>
    </Modal>
  );
};

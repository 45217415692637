



const PrimaryButton = ({
  buttonText,
  styleclass,
  onClick,
  icon,
  hasIcon,
  size,
  disabled,
  type,
  style
}) => {
  return (
    <button
    style={style}
    onClick={onClick}
    className={`${styleclass} simpla-btn` }
    size={size}
    disabled={disabled}
    type={type}
  >
    {hasIcon && <span>{icon !== "" && icon}</span>}
    {buttonText}
  </button>
  );
};

export default PrimaryButton;



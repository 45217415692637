import { createSlice } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";

//const {t} = useTranslation()

// const initialState: CounterState = {value:0} // annotations are only for TypeScript
const chatModeState = [
    {
    name: 'accounting',
    nameTranslated: "",
    nameArabic: "المحاسبة",
    isActive: false,
    description: 'This is based on International Financial Reporting Standards (IFRS)',
    descriptionTranslated: "",
    descriptionArabic: "وفقًا للمعايير الدولية في التقرير المالي (IFRS)",
  }, 
  {
    name: 'tax',
    nameTranslated: "",
    nameArabic: "الضريبة",
    isActive: false,
    description: 'This is based on the location selection of your current profile',
    descriptionTranslated: "",
    descriptionArabic: "وفقًا لاختيار الموقع في حسابك الشخصي الحالي",
  },
//   {
//   name: 'Normal',
//   isActive: false,
//   description: 'This is based on the location selection of your current profile'
// }
]

const chatModeSlice = createSlice({
    name:'chatMode',
    initialState: chatModeState,
    reducers: {
        enableChatMode: (state, action) => {
            state.forEach((mode)=>{
                mode.isActive = false
            })
            state.forEach((mode)=>{
                if (mode.name === action.payload){
                    mode.isActive=true;
                }
            })
        },

        disableChatMode: (state, action) => {
            state.forEach((mode)=>{
                if (mode.name === action.payload){
                    mode.isActive=false;
                }
            })
        },

        defaultChatMode: (state)=>{
            state = [...chatModeState]
        },

        disableAllChatMode: (state) => {
            state.forEach((mode)=>{
                mode.isActive = false
            })
        },

        setTranslatedLabel: (state, action) => {
            const chatmode = state.find((mode) => mode.name === action.payload.name )
            chatmode = {
                ...chatmode,
                nameTranslated: action.payload.nameTranslated
            }            
        }

    }
})

export const {enableChatMode, disableChatMode, defaultChatMode, disableAllChatMode} = chatModeSlice.actions;
export default chatModeSlice.reducer
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  refetchValue: false,
};

const historyRefetchSlice = createSlice({
  name: 'historyRefetchState', 
  initialState,
  reducers: {
    setRefetchValue: (state, action) => {
      state.refetchValue = action.payload;
    },
  },
});

export const { setRefetchValue } = historyRefetchSlice.actions;
export default historyRefetchSlice.reducer;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./analysis.scss";
import { LogiIconFile, UploadIcon } from "../../assets/SVG/svg";
import ProgressBarCustom from "../../components/common/ProgressBar/progressbar";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  getDocumentType,
  analyticsBatch,
  analyticsUpload,
} from "../../services/api";
//import FormSelectRounded from "../../components/common/FormSelectRounded";
import SecondaryButton from "../../components/common/Button/ButtonSecondary";
import { useTracking } from "react-tracking";
import { useTranslation } from "react-i18next";
import FormSelectRoundedVanilla from "../../components/common/FormSelectRounded/indexVanilla";
import AnalyseFormSelectRounded from "../../components/common/FormSelectRounded/AnalyseFormCustom";
const Analyze = () => {
  
  const { trackEvent } = useTracking({ component: 'Analysis Module' });

  const navigate = useNavigate();
  const [activeType, setActiveType] = useState("");
  const [documentType, setDocumentType] = useState();
  const [firstFileUploaded, setFirstFileUploaded] = useState(false);
  const [batch, setBatch] = useState();
  const [selectedFile, setSelectedFile] = useState([]);
  const batchUpload = useMutation(analyticsBatch);
  const fileUpload = useMutation(analyticsUpload);
  const { isFetching } = useQuery(["documentType"], getDocumentType, {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data) => {
      setDocumentType(data?.data?.object);
    },
  });
  const UploadHandler = (event) => {

    //console.log(event);

    const formData = new FormData();
    formData.append("file", event.target.files[0]);

    if (!firstFileUploaded) {
      createBatchCall(event.target.files[0], activeType);
      setFirstFileUploaded(true);
    } else {
      addFileCall(formData, batch, activeType);
    }
    const newProgressEntry = { file: event.target.files[0], progress: 75 };
    setSelectedFile([...selectedFile, newProgressEntry]);
    //console.log("selected file ==> " + JSON.stringify(newProgressEntry.file.name))

    trackEvent({ event_type: "upload", event_name:"File upload", event_data: {action:"file uploaded in Analyse Module", filename:`${newProgressEntry.file.name}`}})
    setDocumentType((prevItems) =>
      prevItems.map((item) =>
        item.id == activeType ? { ...item, disable: true } : item
      )
    );
    setActiveType("");
  };
  const createBatchCall = (val, activeType) => {
    batchUpload.mutate(
      {
        name: "Analysis",
        profile_id: localStorage.getItem("profileid"),
      },
      {
        onSuccess: (data, variables, context) => {
          setBatch(data?.data?.object?.id);
          const formData = new FormData();
          formData.append("file", val);
          addFileCall(formData, data?.data?.object?.id, activeType);

          //console.log("file ==> " + val)

        },
        onError: (error, variables, context) => {
          setDocumentType((prevItems) =>
            prevItems.map((item) =>
              item.id == activeType ? { ...item, disable: false } : item
            )
          );
        },
      }
    );
  };

  const addFileCall = async (file, batch, activeType) => {
    // console.log(`
    // file=${file}
    // batch=${batch}
    // active type=${activeType}
    // `)
    
    try {
      const response = await fileUpload.mutateAsync({
        file: file,
        profile: localStorage.getItem("profileid"),
        batch: batch,
        type: activeType,
      });
      setSelectedFile((prevSelectedFiles) => {
        const updatedSelectedFiles = prevSelectedFiles.map((progressEntry) => {
          if (progressEntry.file === file.get("file")) {
            return { ...progressEntry, progress: 100 };
          }
          return progressEntry;
        });
        return updatedSelectedFiles;
      });
    } catch (error) {

      trackEvent({
        event_type: "error", 
        event_name:"Failed to add file in Analyse Module", 
        event_data:{
          error: `${error}`
        }
      })

      setSelectedFile((prevSelectedFiles) => {
        const updatedSelectedFiles = prevSelectedFiles.map((progressEntry) => {
          if (progressEntry.file === file.get("file")) {
            return { ...progressEntry, progress: 0 };
          }
          return progressEntry;
        });
        return updatedSelectedFiles;
      });
      setDocumentType((prevItems) =>
        prevItems.map((item) =>
          item.id == activeType ? { ...item, disable: false } : item
        )
      );
    }
  };

  const {t} = useTranslation()
  return (
    <>
      <div className="invoice-wrapper">
        <div className="invoice-container Invoice-wrapper-file">
          <div className="invoice-container-top" >
            <h2> {t("analyse_upload1")} </h2>
            <span>
            {t("analyse_upload3")}
            </span>
            <span style={{      direction:  `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "rtl" : "ltr"}`,
}}> {t("file_upload_type")} </span>
          </div>
          <AnalyseFormSelectRounded
            locale={`${localStorage.getItem('i18nextLng')}`}
            options={documentType?.filter((item) => item.disable !== true)}
            text={`${t("select_type")}`}
            value={activeType}
            onChange={(key, val) => setActiveType(val)}
          />
          <div className={`upload-wrapper ${activeType === "" ? "disabledfield " : ""}`} >

            <input
              type="file"
              id="file"
              className="file-Input"
              multiple
              onChange={UploadHandler}
              accept=".csv,.pdf,.xlsx"
              disabled={activeType === ""}
            />

            <UploadIcon />
            <div className="upload-wrapper-text">
              <span className="upload-wrapper-text-browser">
                <span> {t("analyse_upload2")} </span>
              </span>
              <span className="upload-wrapper-text-dragDrop">
              {t("analyse_upload_body")}
              </span>

            </div>
          </div>
          <ul id="fileList2" className="Upload-file-wrapper">
            {selectedFile?.map((val, index) => (
              <li className="list" key={index}>
                <div className="list-inner-box">
                  <div className="list-inner-box-title">
                    <div className="img-name-wrapper">
                      <span className="file-icon excel-file"></span>
                      <span>{val?.file?.name}</span>
                    </div>
                    {val?.progress === 100 && (
                      <div className="uploaded upload-common "></div>
                    )}

                    {val?.progress === 0 && (
                      <div className="cross-upload upload-common"></div>
                    )}
                  </div>
                  <ProgressBarCustom progress={val?.progress} />
                </div>
              </li>
            ))}
          </ul>
          {firstFileUploaded && (
            <SecondaryButton
              buttonText={`${t("next")}`}
              styleclass={"btn-process next-btn"}
              icon={undefined}
              hasIcon={false}
              size={undefined}
              type="submit"
              disabled={fileUpload?.isLoading || selectedFile.length < 1}
              onClick={() =>
                navigate(`/analyse/${localStorage.getItem("profileid")}/${batch}`)
              }
            />)}
        </div>
      </div>
    </>
  );
};
export default Analyze;

import { createSlice } from "@reduxjs/toolkit";


const requestActiveStatusTrackerSlice = createSlice({
    name:'requestActive',
    initialState: false,
    reducers: {
        setRequestActive: (state, action) => {
            return action.payload
        }
    }
})

export const {setRequestActive} = requestActiveStatusTrackerSlice.actions;
export default requestActiveStatusTrackerSlice.reducer
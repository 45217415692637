import { create } from "@mui/material/styles/createTransitions";
import { createSlice } from "@reduxjs/toolkit";


let initialState = {
    open: false,
    tab: "",
}
const profileSettingModalSlice = createSlice({
    name:'profileSettingModal',
    initialState: initialState,
    reducers: {
        setprofileSettingModalOpen: (state, action) => {
             state.open = action.payload;
        },
        setprofileSettingModalTab: (state, action) => {
            state.tab = action.payload;
        }
    }
})

export const {setprofileSettingModalOpen, setprofileSettingModalTab} = profileSettingModalSlice.actions;
export default profileSettingModalSlice.reducer
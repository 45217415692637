import React, { useEffect, useState } from 'react';
import Toast from 'react-bootstrap/Toast';
export const Notification = ({ message, variant,open,setNotifications }) => {
  const [show, setShow] = useState(open);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
      setNotifications()
    }, 5000);

    return () => clearTimeout(timer);
  }, [1000]);

  return (
    <>
      {show && (
        <div
        style={{
          position: 'fixed',
          top: '20px',
          right: `${localStorage.getItem("i18nextLng") === "ar" ? "" : "20px"}`,
          left: `${localStorage.getItem("i18nextLng") === "ar" ? "20px" : ""}`,
          zIndex: '9999',
        }}
      >
  <Toast
  className="d-inline-block m-1 custom-toast"
  bg={variant}
>
  <Toast.Body style={{background: "black", color:"#faf5e1"}} className={'text-white'}>
    {message}
  </Toast.Body>
</Toast></div>
      )}
    </>
  );
};


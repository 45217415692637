import React from "react";
import Alert from "react-bootstrap/Alert";
import "./alert.scss";
import { CheckedTrue } from "../../../assets/SVG/svg";
const AlertCustom = (props) => {
  return (
    <Alert key={props.variant} variant={props.variant}>
      <span className="alert-icon">
        <CheckedTrue />
      </span>
      {props.text}
    </Alert>
  );
};
export default AlertCustom;

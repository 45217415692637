import React, { useState, useEffect } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./progressbar.scss";
const ProgressBarCustom = (props) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (progress < props?.progress) {
        setProgress(progress + 1);
      }
    },  25); // Default interval is 100ms

    return () => clearInterval(interval);
  }, [progress, props]);
  return <ProgressBar now={progress} variant="warning" hidden={progress===100||props?.progress===0} />;
};

export default ProgressBarCustom;

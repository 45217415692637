
import { Navigate,Outlet } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import { useContext } from "react";
export const UnAuthenticatedRoutes = ({
    children,
  }) => {
    const { token } = useContext(AuthContext);
    if (Boolean(token)) {
      return <Navigate to='/' replace />;
    }
  
    return children ? children : <Outlet />;
  };
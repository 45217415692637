import React, { useEffect, useState } from "react";
import Joyride from "react-joyride";
import { useTour } from "./TourContext";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
 import "./joyride.scss";
import { UseDispatch, useDispatch } from "react-redux"; 
import { setDisplaySidebar } from "../../../ReduxSlices/SidebarSlice";
import { useTracking } from "react-tracking";
import { useTranslation } from "react-i18next";

// if (localStorage.getItem('i18nextLng') === "ar") {
//   import("./joyride-ar.scss");
// } else {
//   import("./joyride.scss");
// }


const JoyrideComponent = () => {
  const dispatch = useDispatch();

  const {trackEvent} = useTracking()
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Adds a smooth scrolling effect
    });
  }

  const [currentStep, setCurentStep] = useState({
    index: 0,
    feature_name: "",
  })

  const getStepTitle = (index) => {
    if (index >= 0 && index < tourSteps.length) {
      const step = tourSteps[index];
      const contentText = step.content.props.children[1].props.children;
      const titleBeforeHyphen = `${contentText}`.split(' - ')[0]?.trim();
      return titleBeforeHyphen;
    } else {
      return "N/A"; // Handle invalid index or out-of-bounds index
    }
  };

  const navigate = useNavigate();
  const { tourState, nextStep, endTour, prevStep,setTourJump } = useTour();
  const {t} = useTranslation()
  const headerJoyride = (
    <div className="joyride_header">
      <label className="form-label"> {t("joyride_guide_me")} </label>
      <Dropdown>
        <Dropdown.Toggle id="dropdown-basic" className="bg-transparent border-0">
        {t("joyride_select_feature")}
        </Dropdown.Toggle>
  
        <Dropdown.Menu>
          <Dropdown.Item onClick={()=>{
            navigate('/')
            setTourJump(0)
            setCurentStep({...currentStep, index: 0, feature_name: "Advise"})
            }}>{t("advise")}</Dropdown.Item>
          <Dropdown.Item onClick={()=>{            navigate('/')
             setCurentStep({...currentStep, index: 1, feature_name: "How to create a new chat"})
             setTourJump(1)}}>{t("joyride_create_newchat")}</Dropdown.Item>
          <Dropdown.Item onClick={()=>{            navigate('/')            
          setCurentStep({...currentStep, index: 0, feature_name: "History"})
          setTourJump(2)}}>{t("history")}</Dropdown.Item>
          <Dropdown.Item onClick={()=>{            navigate('/')
            setCurentStep({...currentStep, index: 3, feature_name: "Module Toggle"})
            setTourJump(3)}}>{t("module_toggle")}</Dropdown.Item>
          <Dropdown.Item onClick={()=>{            navigate('/invoice')
            setCurentStep({...currentStep, index: 4, feature_name: "Process Module"})
            setTourJump(4)}}>{t("joyride_process_mode")}</Dropdown.Item>
          <Dropdown.Item onClick={()=>{
             navigate('/invoiceOnboarding')
             setCurentStep({...currentStep, index: 5, feature_name: "Amend your data"})
            setTourJump(5)}}>{t("joyride_amend_data")}</Dropdown.Item>
          <Dropdown.Item onClick={()=>{
                         navigate('/invoiceOnboarding')
                         setCurentStep({...currentStep, index: 6, feature_name: "Process and Download or Submit"})
            setTourJump(6)}}>{t("process_download")}</Dropdown.Item>
          <Dropdown.Item onClick={()=>{
                         navigate('/analyse')
                         setCurentStep({...currentStep, index: 7, feature_name: "Analyse"})

            setTourJump(7)}}>{t("analyse")}</Dropdown.Item>
          <Dropdown.Item onClick={()=>{             navigate('/analyseOnboarding')
                                   setCurentStep({...currentStep, index: 8, feature_name: "Analyse output and reporting"})

            setTourJump(8)}}>{t("analyse_output")}</Dropdown.Item>
                      <Dropdown.Item onClick={()=>{ navigate('/')
                                               setCurentStep({...currentStep, index: 9, feature_name: "Account Settings"})

            setTourJump(9)}}>{t("option_edit_profile")}</Dropdown.Item>
          <Dropdown.Item onClick={()=>{
            navigate('/analyseOnboarding')
            setCurentStep({...currentStep, index: 10, feature_name: "Feedback"})

            setTourJump(10)}}>{t("feedback")}</Dropdown.Item>
          <Dropdown.Item onClick={()=>{
            navigate('/analyseOnboarding')
            setCurentStep({...currentStep, index: 11, feature_name: "Help-link to revisit to tutorial"})
            setTourJump(11)}}
            >{t("help_link")}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );

  function feedbackBtnPlacement(){
    let alignment = "";

    if (window.innerWidth > 450 && window.innerWidth <= 750 && localStorage.getItem('i18nextLng')?.toString() === "ar") {
      alignment = "center";
    } 
    else if (window.innerWidth > 450 && window.innerWidth <= 750){
      alignment = "center"
    }
    else if (window.innerWidth < 450 && localStorage.getItem('i18nextLng')?.toString() === "ar") {
      alignment = "right";
    } 
    else if (window.innerWidth < 450){
      alignment = "left"
    }
    else if (window.innerWidth > 750 && localStorage.getItem('i18nextLng')?.toString() === "ar") {
        alignment = "left";
      } 
    else {
        alignment = "right";
      }

      return alignment;
    }

  
  const tourSteps = [
    {
      target: ".advise-module-target",
      content: (
        <div >
          {headerJoyride}
          <p className="joyride_body">
            {t("joyride_advise")}
          </p>
          <div className="tooltip_counting">{t("tip")}1/12</div>
        </div>
      ),
      disableBeacon: true,

    },
    {
      target: `${window.innerWidth < 750 ? ".add-btn-mobile" : ".add-btn-desktop"}`,
      content: (
        <div>
          {headerJoyride}
          <p className="joyride_body">
          {t("joyride_new_chat")}
          </p>
          <div className="tooltip_counting">{t("tip")}2/12</div>
        </div>
      ),
      placement:`${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "left" : "right"}`
    },
    {
      target: window.innerWidth < 750 ? ".btn-history-mobile" : ".btn-history-desktop",
      content: (
        <div>
          {headerJoyride}
          <p className="joyride_body">
            {t("joyride_history")}
          </p>
          <div className="tooltip_counting">{t("tip")}3/12</div>
        </div>
      ),
      page: "/",
      placement:`${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "left" : "right"}`
    },
    {
      target: ".Tab-control-wrapper",
      content: (
        <div>
          {headerJoyride}
          <p className="joyride_body">
          {t("joyride_module_toggle")}
          </p>
          <div className="tooltip_counting">{t("tip")}4/12</div>
        </div>
      ),
      page: "/",
    },
    {
      target: ".upload-container-files",
      placement: "top-start",
      content: (
        <div>
          {headerJoyride}
          <p className="joyride_body">
          {t("joyride_process")}
          </p>
          <div className="tooltip_counting">{t("tip")}5/12</div>
        </div>
      ),
      page: "/invoice",
    },
    {
      target: ".adding_row",
      content: (
        <div>
          {headerJoyride}
          <p className="joyride_body">
          {t("joyride_amend")}</p>
          <div className="tooltip_counting">{t("tip")}6/12</div>
        </div>
      ),
      page: "/invoiceOnboarding",
    },
    {
      target: ".table-footer",
      content: (
        <div>
          {headerJoyride}
          <p className="joyride_body">
          {t("joyride_magic")}
          </p>
          <div className="tooltip_counting">{t("tip")}7/12</div>
        </div>
      ),
      page: "/invoiceOnboarding",
    },
    {
      target: ".Invoice-wrapper-file",
      content: (
        <div>
          {headerJoyride}
          <p className="joyride_body">
          {t("joyride_analyse")}
          </p>
          <div className="tooltip_counting">{t("tip")}8/12</div>
        </div>
      ),
      page: "/analyse",
    },
    {
      target: ".AnalyzeData-wrapper-Onboarding",
      content: (
        <div>
          {headerJoyride}
          <p className="joyride_body">
          {t("joyride_analyse2")}
          </p>
          <div className="tooltip_counting">{t("tip")}9/12</div>
        </div>
      ),
      page: "/analyseOnboarding",
    },
    {
      target: window.innerWidth < 750 ? ".profile-dropdown-mobile" : ".profile-dropdown",
      content: (
        <div>
          {headerJoyride}
          <p className="joyride_body">
            {t("acc_setting_description")}
          </p>
          <div className="tooltip_counting">{t("tip")}10/12</div>
        </div>
      ),
      placement:'left'
    },
    {
      target: ".feedback-btn",
      content: (
        <div>
          {headerJoyride}
          <p className="joyride_body">
          {t("joyride_feedback")}
          </p>
          <div className="tooltip_counting">{t("tip")}11/12</div>
        </div>
      ),
      page: "/",
      placement: `${feedbackBtnPlacement()}`

    },
    {
      target: `${window.innerWidth < 750 ? ".btn-help-mobile" : ".btn-help-desktop"}`,
      content: (
        <div>
          {headerJoyride}
          <p className="joyride_body">
          {t("joyride_help")}          </p>
          <div className="tooltip_counting">{t("tip")}12/12</div>
        </div>
      ),
      page: "/analyseOnboarding",
      placement:`${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "left" : "right"}`

    },
  ];

  useEffect(()=>{
    if (tourState.isTourActive && (tourState.currentStep < tourSteps.length)){
    trackEvent({event_type: "click",event_name: "Product Tour Step Changed", event_data: {index: (tourState.currentStep), feature_name: `${getStepTitle(tourState.currentStep)}`}})          
    }
  }, [tourState.currentStep])

  useEffect(() => {
    if (tourState.isTourActive && (tourState.currentStep >= tourSteps.length)) {
      // Tour has reached the end
      endTour();
      
    }

  }, [tourState.isTourActive, tourState.currentStep]);

  useEffect(() => {
    const loadScssFile = async () => {
      const language = localStorage.getItem("i18nextLng");
      if (language === "ar") {
        await import("./joyride-ar.scss");
      } else {
        await import("./joyride.scss");
      }
    };
    loadScssFile();
  }, []);

  useEffect(() => {
    // console.log("hideFeedbackBtn ", hideFeedbackBtn)
    const handleResize = () => {
        //console.log(window.innerWidth)
        // if (window.innerWidth <= 750){
        //     setHideFeedbackBtn(true)
        // }
        // else {
        //     setHideFeedbackBtn(false)
        // }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Joyride
      locale={ { back: `${t("back")}`, close: `${t("close")}`, last: `${t("last")}`, next: `${t("next")}`, open: `${t("open")}`, skip: `${t("skip")}` }}
      steps={tourSteps}
      buttonNext="custom-next-button"
      continuous={true}
      run={tourState.isTourActive}
      stepIndex={tourState.currentStep}
      showSkipButton={false}
      styles={{
        options: {
          arrowColor: "#141E14",
          padding: "16px 12px",
          backgroundColor: "#141E14",
          overlayColor: "rgba(77, 79, 92, 0.20)",
          primaryColor: "#red",
          textColor: "#FFF",
          width: 370,
          zIndex: 1000,
          marginLeft:'100px',
          direction:"ltr"
        },
      }}
      // showSkipButton={true}
      callback={(e) => {

        if(window.innerWidth <= 750 && (e.index === 0 || e.index === 3 || e.index === 4 || e.index === 5 || e.index === 6 || e.index === 7 || e.index === 8)){
          //console.log('sidebar = none')
          dispatch(setDisplaySidebar('none'))
          scrollToTop()
        }

        else if (window.innerWidth <= 750 && (e.index === 1 || e.index === 2 || e.index === 11)){
          scrollToTop()
          dispatch(setDisplaySidebar('block'))
        }

        else if (window.innerWidth <=750 && (e.index === 10)){
          //scroll to bottom
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth' // Add smooth scrolling behavior if desired
          });
          dispatch(setDisplaySidebar('none'))
        }

        if(e.index===tourState.currentStep){

          

        if (e?.step?.page) {
          //('page=' + e.index + "\nroute=" + e.step.page)
          // Switch the page first
          navigate(e.step.page);
        }
     
        if (e.action === "next" && e.type === "step:after") {
          //console.log("next step");     
          nextStep();
         
        }
         else if (e.action === "prev" && e.type === "step:after") {
          //console.log("previous step");
          prevStep();

        }}
         if (e.action === "close"||e.type==="tour:end"||e.action==='stop') {
              trackEvent({event_type: "click",event_name: "User Ended Product Tour", event_data: {action: "Tutorial was ended"}})
          endTour();  
          navigate('/');

        }
      }}
    />
  );
};
export default JoyrideComponent;

import React from "react";
import Table from "react-bootstrap/Table";
import "../dataResults/dataresults.scss";
import CheckboxCustom from "../../components/common/checkbox/checkbox";
import SecondaryButton from "../../components/common/Button/ButtonSecondary";
import {
  BackIcon,
  PlusIconDark,
  TrashIcon,
} from "../../assets/SVG/svg";
import { useTranslation } from "react-i18next";


const DataResultsOnboarding = (props) => {

  const {t} = useTranslation()
  return (
    <div className="data-results-container">
      <div className="title-bar">
        <div className="back-btn">
          <BackIcon />
        </div>
        <span className="title-bar-text">{t("imported_results")}</span>
      </div>

      <div className="imported-wrapper">
        <Table responsive className="data-table">
          <thead>
            <tr>
              <th className="sticky-column">
                <CheckboxCustom type="checkbox" label="" />
              </th>

              <th>File Name</th>
              <th>File Name</th>
              <th>File Name</th>
              <th>File Name</th>
              <th>File Name</th>
              <th>File Name</th>
              <th>File Name</th>
              <th>File Name</th>
              <th>File Name</th>

              <th className="sticky-column-right">
                <TrashIcon />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="sticky-column">
                <CheckboxCustom type="checkbox" label="" />
              </td>

              <td>Abc Xyz</td>
              <td>Abc Xyz</td>
              <td>Abc Xyz</td>
              <td>Abc Xyz</td>
              <td>Abc Xyz</td>
              <td>Abc Xyz</td>
              <td>Abc Xyz</td>
              <td>Abc Xyz</td>
              <td>Abc Xyz</td>

              <td className="sticky-column-right">
                <TrashIcon />
              </td>
            </tr>
            <tr>
              <td className="adding_row">
                <PlusIconDark />
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="table-footer">
          <div className="table-footer-container">
            <SecondaryButton
              buttonText={`${t("download")}`}
              styleclass={"btn-transparent"}
              icon={undefined}
              hasIcon={false}
              size={undefined}
              type="submit"
            />
            <SecondaryButton
              buttonText={`${t("process")}`}
              styleclass={"btn-process"}
              icon={undefined}
              hasIcon={false}
              size={undefined}
              type="submit"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default DataResultsOnboarding;

import React from "react";
const ButtonRounded = ({
  buttonIcon,
  styleclass,
  btnBorder,
  onClick,
  tooltipText,
  hide_btn
}) => {
  return (
    <>
      <button
        hidden={hide_btn}
        className={`${styleclass} ${btnBorder} btn-rounded`}
        onClick={onClick}
      >
        {buttonIcon !== "" && buttonIcon}
        <div className="tooltipTextWrapper">
          <span className="tooltiptext">{tooltipText}</span>
        </div>
      </button>
    </>
  );
};
export default ButtonRounded;

import React from "react";
import Form from "react-bootstrap/Form";
const FormSelectRoundedVanilla = ({
  label,
  options,
  value,
  keyVal,
  onChange,
  disabled,
  styleclass,
  text,
}) => {

 
  return (<>
    <label className="form-label">{label}</label>
    <Form.Select
      aria-label={label}
      className={`form-select-rounded ${styleclass} `}
      value={disabled ? "" : value}   
      onChange={(e)=>onChange(keyVal,e.target.value,e.target.options[e.target.selectedIndex].getAttribute('data-value'))}
      disabled={disabled}
    >
  {value === ""    && <option value="">{text}</option>}
 
      {options?.map((item) => (
        <option value={item.id} keyy={item.id} disabled={item?.disable} data-value={item.value} key={item?.id}>
          {item.value}
        </option>
      ))}
    </Form.Select></>
  );
};
export default FormSelectRoundedVanilla;
import React from "react";
import "./checkbox.scss";
import Form from "react-bootstrap/Form";
const CheckboxCustom = (props) => {
  return (
    <>
      <Form.Check inline name={props.name} type={props.type} onChange={props?.onChange} checked={props?.checked}
      value={props?.value}/>
    </>
  );
};
export default CheckboxCustom;

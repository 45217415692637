import { create } from "@mui/material/styles/createTransitions";
import { createSlice } from "@reduxjs/toolkit";


let initialState = {
    id: "",
    name: "",
    "is_subscribed": false,
    "paid_plan": false,
    "valid_date": null

}
const currentPlanSlice = createSlice({
    name:'currentPlan',
    initialState: initialState,
    reducers: {
        setCurrentPlan: (state, action) => {
             state.name = action.payload;
        },
        setCurrentPlanId: (state, action) => {
            state.id = action.payload;
        },
        setPlanSubStatus: (state, action) => {
            state.is_subscribed = action.payload;
        },
        setPlanPaidStatus: (state, action) => {
            state.paid_plan = action.payload;
        },
        setPlanValidity: (state, action) => {
            state.valid_date = action.payload;
        },

    }
})

export const {setCurrentPlan, setCurrentPlanId, setPlanPaidStatus, setPlanSubStatus, setPlanValidity} = currentPlanSlice.actions;
export default currentPlanSlice.reducer
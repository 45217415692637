const SecondaryButton = ({
  buttonText,
  styleclass,
  onClick,
  icon,
  hasIcon,
  size,
  disabled,
}) => {
  return (
    <button onClick={onClick} className={`${styleclass} btn-common`} disabled={disabled}>
      {hasIcon && <span>{icon !== "" && icon}</span>}
      {buttonText}
    </button>
  );
};

export default SecondaryButton;

import { SERVICES } from "../utils/ConstantSettings";
import axios from "axios";
//onboarding apis
export const signup = (payload) =>
  axios.post(`${SERVICES.apiBaseUrl}/user/signup`, payload);
export const signin = (payload) =>
  axios.post(`${SERVICES.apiBaseUrl}/user/login`, payload);
export const forget = (payload) =>
  axios.put(`${SERVICES.apiBaseUrl}/user/forgot-password`, payload);
export const reset = (payload) => {
  return axios.post(`${SERVICES.apiBaseUrl}/user/reset-password`, payload);
};
//google apis
export const getUrl = (value) =>
  axios.get(`${SERVICES.apiBaseUrl}/user/oauth/get-url?client_type=${value}`);

export const getUserInfo = (payload) => {
    return axios.get(`${SERVICES.apiBaseUrl}/user`, {
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
      },
    });
  };


  export const verifyEmail=async(payload)=>{
    const data=await axios.post(`${SERVICES.apiBaseUrl}/user/verify-email`,{token:payload});
    return data
  }

  // export const sendTrackingData = async (payload) => {
  //   axios.post(`${SERVICES.apiBaseUrl}/tracking/logActivity` , payload)
  // }



//profile apis
export const profile = (payload) => {
  return axios.post(`${SERVICES.apiBaseUrl}/user/profile`, payload.value, {
    headers: {
      Authorization: `Bearer ${payload.token}`,
    },
  });
};
export const getprofile = (payload) => {
  return axios.get(`${SERVICES.apiBaseUrl}/user/profile`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};


export const updateDisclaimerStatus = (payload) => {
  return axios.post(`${SERVICES.apiBaseUrl}/user/update_disclaimer`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const getprofileInfo=()=>{
  return axios.get(`${SERVICES.apiBaseUrl}/user`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

}
export const updateuserInfo=(payload)=>{
  return axios.patch(`${SERVICES.apiBaseUrl}/user/update`,payload.user, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

}
export const updateProfileInfo=(payload)=>{
  return axios.patch(`${SERVICES.apiBaseUrl}/user/profile/${payload?.profile}`,payload.values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

}
//chat apis
export const chatapi = (payload) => {
  return axios.post(`${SERVICES.apiBaseUrl}/advise/conversation`, payload, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const chatroom = (payload) => {
  return axios.post(`${SERVICES.apiBaseUrl}/advise/chatroom`, payload, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const getchatroom = ({ queryKey }) => {

  return axios.get(`${SERVICES.apiBaseUrl}/advise/chatroom/${queryKey[1]}?${queryKey[2]}${queryKey[3]}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const getChats = ({ queryKey }) => {

  return axios.get(
    `${SERVICES.apiBaseUrl}/advise/conversation/${queryKey[1]}/${queryKey[2]}?page=${queryKey[3]}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};
export const editChatroom = (payload) => {
  return axios.patch(`${SERVICES.apiBaseUrl}/advise/chatroom/${payload.profile}/${payload.id}`, payload.val, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const deleteChatroom = (payload) => {
  return axios.delete(`${SERVICES.apiBaseUrl}/advise/chatroom/${payload.profile}/${payload.id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const addReaction = (payload) => {
  return axios.patch(`${SERVICES.apiBaseUrl}/advise/conversation/reaction`, payload, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const exportChats = (payload) => {
  return axios.get(
    `${SERVICES.apiBaseUrl}/advise/conversation/export/${payload.profile}/${payload.chatroom}/${payload.id}`, {
      responseType: 'blob', // Indicate that you expect a binary response
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
};

//invoice apis
export const createBatch = (payload) => {
  return axios.post(`${SERVICES.apiBaseUrl}/process/batch`, payload, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const invoiceUpload = (payload) => {
  return axios.post(`${SERVICES.apiBaseUrl}/process/invoice/${payload.profile}/${payload.batch}`, payload.file, {
    headers: {
      'Content-Type': 'multipart/form-data', // Set the correct content type for file upload
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const editBatch = (payload) => {
  return axios.patch(`${SERVICES.apiBaseUrl}/process/batch/${payload.profile}/${payload.id}`, payload.val, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const deleteBatch = (payload) => {
  return axios.delete(`${SERVICES.apiBaseUrl}/process/batch/${payload.profile}/${payload.id}`,  {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const editInvoice = (payload) => {
  return axios.patch(`${SERVICES.apiBaseUrl}/process/invoice/${payload.profile}/${payload.batch}/${payload.id}`, payload.val, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const deleteInvoice = (payload) => {
  return axios.delete(`${SERVICES.apiBaseUrl}/process/invoice/${payload.profile}/${payload.batch}`,{data:payload.val,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const processInvoice = ({queryKey}) => {
  return axios.get(`${SERVICES.apiBaseUrl}/process/${queryKey[0]}/${queryKey[1]}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const downloadInvoice = (payload) => {
  return axios.get(`${SERVICES.apiBaseUrl}/process/download/${payload.profile}/${payload.batch}/${payload.type}`, {
    responseType: 'blob', // Indicate that you expect a binary response
    headers: {
 
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    
    },
  });
};
export const submitInvoice = (payload) => {
  return axios.get(`${SERVICES.apiBaseUrl}/process/submit/${payload.profile}/${payload.batch}/${payload.type}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const getAllBatches = ({ queryKey }) => {
  return axios.get(`${SERVICES.apiBaseUrl}/process/batch?profile_id=${queryKey[1]}&${queryKey[2]}${queryKey[3]}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const getInvoice = ({queryKey}) => {
  return axios.get(`${SERVICES.apiBaseUrl}/process/invoice/${queryKey[1]}/${queryKey[2]}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
//lineItems apis
export const editLineitem = (payload) => {
  return axios.patch(`${SERVICES.apiBaseUrl}/process/lineitem/${payload.profile}/${payload.batch}/${payload.id}`, payload.val, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const deleteLineitem = (payload) => {
  return axios.delete(`${SERVICES.apiBaseUrl}/process/lineitem/${payload.profile}/${payload.batch}/${payload.id}`,{
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

//feedback apis
export const createFeedback = (payload) => {
  return axios.post(`${SERVICES.apiBaseUrl}/feedback`, payload, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

//analytics apis
//dropdown values
export const getDocumentType = () => {
  return axios.get(`${SERVICES.apiBaseUrl}/analytics/dropdown/document-type`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const getAnalyticType = () => {
  return axios.get(`${SERVICES.apiBaseUrl}/analytics/dropdown/analysis-type`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const getFinancialMetric = ({queryKey}) => {
  return axios.get(`${SERVICES.apiBaseUrl}/analytics/dropdown/financial-metric/${queryKey[1]}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const getSpecificMetric = ({queryKey}) => {
  return axios.get(`${SERVICES.apiBaseUrl}/analytics/dropdown/specific-metric/${queryKey[2]}/${queryKey[3]}/${queryKey[1]}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
//upload files
export const analyticsUpload = (payload) => {
  return axios.post(`${SERVICES.apiBaseUrl}/analytics/context-document/${payload.profile}/${payload.batch}/${payload.type}`, payload.file, {
    headers: {
      'Content-Type': 'multipart/form-data', // Set the correct content type for file upload
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
//batch apis

export const analyticsBatch  = (payload) => {
  return axios.post(`${SERVICES.apiBaseUrl}/analytics/batch`, payload, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
//analyseHistory
export const getAnalyticsHistory = ({queryKey}) => {
  return axios.get(`${SERVICES.apiBaseUrl}/analytics/batch?profile_id=${queryKey[1]}&${queryKey[2]}${queryKey[3]}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const editAnalyticsHistory = (payload) => {
  return axios.patch(`${SERVICES.apiBaseUrl}/analytics/batch/${payload.profile}/${payload.id}`, payload.val,{
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const deleteAnalyticsHistory = (payload) => {
  return axios.delete(`${SERVICES.apiBaseUrl}/analytics/batch/${payload.profile}/${payload.id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
//get results history
export const getResultsHistory = ({queryKey}) => {
  return axios.get(`${SERVICES.apiBaseUrl}/analytics/history/${queryKey[1]}/${queryKey[2]}${queryKey[3]}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

//generate results
export const generateResults  = (payload) => {
  return axios.post(`${SERVICES.apiBaseUrl}/analytics/generate-results/${payload.profile}/${payload.batch}`, payload.values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const exportAnalystics=(payload)=>{
  return axios.get(
    `${SERVICES.apiBaseUrl}/analytics/export/${payload.profile}/${payload.batch}`, {
      responseType: 'blob', // Indicate that you expect a binary response
      headers: {
   
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      
      },
    });
}
//analytics chat
export const chatAnalytic  = (payload) => {
  return axios.post(`${SERVICES.apiBaseUrl}/analytics/open-chat/${payload.profile}/${payload.batch}`, payload.values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
import React, { useState, useEffect } from "react";
import InputField from "../InputField/InputField";
import FormSelectRounded from "../FormSelectRounded";
import RadioButton from "../RadioButton/RadioButton";
import {
  uaeCity,
  saudiCity,
  businessStatus,
  legalType,
  industry,
  registered,
  jurisdiction,
  years,
  notApplicable,
  all_countries,
  registeredArabic,
} from "../../../utils/SetupDropdown";
import Select from "react-select";
import { useTranslation } from "react-i18next";
const AccountSetting = ({ values, handleValue, select }) => {

  const { t } = useTranslation()

  const radioOptions = [
    { label: `${t("yes")}`, value: true },
    { label: `${t("no")}`, value: false },
  ];
  const [selectedOptionss, setSelectedOptionss] = useState(
    localStorage.getItem('i18nextLng') === "ar" ?
      registeredArabic.filter((option) => values?.registered.includes(option.valueEnglish))
      :
      registered.filter((option) => values?.registered.includes(option.value))
  );


  useEffect(() => {
    //console.log("global - selected options:", selectedOptionss)
    if (localStorage.getItem('i18nextLng') === "ar") {

      //console.log("arabic val filter=", registered.filter((option) => values?.registered.includes(option.value))      )
      //console.log("values?.registered = ",values?.registered)
      setSelectedOptionss(
        registeredArabic.filter((option) => values?.registered.includes(option.valueEnglish))
      );

    }
    else {

      // console.log("reg array filter = ", registered.filter((option) => values?.registered.includes(option.value)))
      // console.log("values?.registered = ",values?.registered)

      setSelectedOptionss(
        registered.filter((option) => values?.registered.includes(option.value))
      );
    }

  }, [values, select]);

  return (
    <div className="ModalBodywrapper">
      <div className="row ">
        <div className="col-md-6">
          <FormSelectRounded
            locale="en"
            options={all_countries}
            text={`${t("select_type")}`}
            label={`${t("country")}`}
            value={values.country}
            onChange={(key, val) => {
              handleValue(val, "country");
            }}
          />
        </div>
        <div className="col-md-6">
          <InputField
            label={`${t("biz_name")}`}
            id="text"
            styleclass="roundedInput"
            placeholder="Enter here"
            value={values.name}
            onChange={(e) => {
              handleValue(e.target.value, "name");
            }}
          />{" "}
        </div>
      </div>

      {/* rest of q/a */}

      <div hidden={values.country === "UAE" || values.country === "KSA" ? false : true}>
        <div className="row mb-3">
          <div className="col-md-12">
            <FormSelectRounded locale={`${localStorage.getItem('i18nextLng')?.toString()}`}
              label={`${t("city")}`}
              options={values.country === "UAE" ? uaeCity : saudiCity}
              value={values.city}
              onChange={(key, val) => {
                if (val == 'Select a city') {
                  handleValue("", "city");
                }
                else
                  handleValue(val, "city");
              }}
              text={`${t("select_type")}`}
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-12">
            <FormSelectRounded locale={`${localStorage.getItem('i18nextLng')?.toString()}`}
              label={`${t("biz_legal")}`}
              options={legalType}
              value={values.legal_type}
              onChange={(key, val) => {
                handleValue(val, "legal_type");
              }}
              text={`${t("select_type")}`}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-12">
            <FormSelectRounded locale={`${localStorage.getItem('i18nextLng')?.toString()}`}
              label={`${t("biz_status")}`}
              options={businessStatus}
              value={values.status}
              onChange={(key, val) => {
                handleValue(val, "status");
              }}
              text={`${t("select_type")}`}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <FormSelectRounded
              locale={`${localStorage.getItem('i18nextLng')?.toString()}`}
              label={`${t("biz_year")}`}
              options={[ ...years()]} // Adding a default option
              value={values.incorporation_year || ""} // Ensure it defaults to the placeholder if no value is set
              onChange={(key, val) => {
                handleValue(val, "incorporation_year");
              }}
              text={`${t("select_type")}`}
            />

          </div>
          <div className="col-md-6">
            <FormSelectRounded locale={`${localStorage.getItem('i18nextLng')?.toString()}`}
              label={`${t("industry")}`}
              options={industry}
              value={values.industry}
              onChange={(key, val) => {
                handleValue(val, "industry");
              }}
              text={`${t("select_type")}`}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <FormSelectRounded locale={`${localStorage.getItem('i18nextLng')?.toString()}`}
              label={`${t("jurisdiction")}`}
              options={jurisdiction}
              value={values.jurisdiction}
              onChange={(key, val) => {
                handleValue(val, "jurisdiction");
              }}
              text={`${t("select_type")}`}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            <InputField
              label={`${t("monthly_transactions")}`}
              type="number"
              styleclass="roundedInput"
              id="text"
              value={values?.monthly_txns}
              onChange={(e) => {
                handleValue(e.target.value, "monthly_txns");
              }}
            />{" "}
          </div>
          <div className="col-md-6">
            <label className="form-label">{`${t("reg_for")}`} </label>
            {/* localStorage.getItem('i18nextLng') === "ar" ? registered.map((item)=>{return {label: item.valueArabic, value: item.valueArabic, labelEnglish: item.label, valueEnglish: item.value}}) : registered */}
            <Select

              closeMenuOnSelect={false}
              // components={animatedComponents}
              placeholder={`${t("select")}`}
              isMulti
              options={localStorage.getItem('i18nextLng') === "ar" ? registeredArabic : registered}
              value={selectedOptionss}
              key={selectedOptionss}
              defaultValue={selectedOptionss}
              className="form-select2-rounded mb-3"
              onChange={(selectedOptions) => {
                //console.log("onChange - selectedOptions",selectedOptions);

                var selectedValues;

                if (localStorage.getItem('i18nextLng') === "ar") {
                  selectedValues = selectedOptions.map(
                    (option) => option.valueEnglish
                  );
                }
                else {
                  selectedValues = selectedOptions.map(
                    (option) => option.value
                  );
                }

                // console.log("selectedValues final",selectedValues)
                handleValue([...selectedValues], "registered");

                setSelectedOptionss(selectedOptions);
              }}
            />{" "}
          </div>
          <div className="row mb-3">
            <div className="col-md-12" >
              <FormSelectRounded locale={`${localStorage.getItem('i18nextLng')?.toString()}`}
                label={`${t("uae_visa")}`}
                options={values.country !== "UAE" ? notApplicable : uaeCity}
                value={values.visa_city}
                onChange={(key, val) => {
                  if (val == 'Select a city') {

                    handleValue("", "visa_city");
                  }
                  else {
                    handleValue(val, "visa_city");
                  }

                }}
                disabled={values.country !== "UAE"}
                text={`${t("select_type")}`}
              />
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <InputField
              label={`${t("lcy_income")}`}
              type="number"
              id="text"
              value={values.taxable_income}
              styleclass="roundedInput"
              onChange={(e) => {
                handleValue(e.target.value, "taxable_income");
              }}
            />{" "}
          </div>
          <div className="col-md-6">
            <InputField
              label={`${t("lcy_expense")}`}
              type="number"
              id="text"
              styleclass="roundedInput"
              value={values.taxable_expenses}
              onChange={(e) => {
                handleValue(e.target.value, "taxable_expenses");
              }}
            />{" "}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <RadioButton
              label={`${t("qlabel_branch")}`}
              options={radioOptions}
              selectedOption={values.branches}
              onChange={(e) => {
                handleValue(e.target.value === "true", "branches");
              }}
            />
            <RadioButton
              label={`${t("qlabel_tax_group")}`}
              options={radioOptions}
              selectedOption={values.tax_group}
              onChange={(e) => {
                handleValue(e.target.value === "true", "tax_group");
              }}
            />

            <RadioButton
              label={`${t("qlabel_import")}`}
              options={radioOptions}
              selectedOption={values.goods_vendor}
              onChange={(e) => {
                handleValue(e.target.value === "true", "goods_vendor");
              }}
            />
            <RadioButton
              label={`${t("qlabel_import2")}`}
              options={radioOptions}
              selectedOption={values.service_vendor}
              onChange={(e) => {
                handleValue(e.target.value === "true", "service_vendor");
              }}
            />
            <RadioButton
              label={`${t("qlabel_import3")}`}
              options={radioOptions}
              selectedOption={values.cross_border_txns}
              onChange={(e) => {
                handleValue(e.target.value === "true", "cross_border_txns");
              }}
            />
          </div>
        </div>

      </div> {/* question container div end */}



    </div>
  );
};
export default AccountSetting;

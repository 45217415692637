import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./modalcustom.scss";
import { ModalCloseIcon } from "../../../assets/SVG/svg";
import congrates from "../../../assets/SVG/greenTick.svg";
import ProgressBarCustom from "../ProgressBar/progressbar";
import { useTranslation } from "react-i18next";
const ModalCustom = (props) => {
  const {t} = useTranslation()
  return (
    <Modal
      className="modal-custom"
      centered
      show={true}
      onHide={props?.onClose}
      aria-labelledby="example-modal-sizes-title-sm"
    >
      {props?.type && (
        <span className="modal-close-btn" onClick={() => props?.onClose()}>
          <ModalCloseIcon />
        </span>
      )}

      <Modal.Body>
        {props?.type ? (
          <div className="modal-custom-body ">
            <div className="TickWrapper">
              <img src={congrates} />
            </div>
            <div className="modal-text-wrapper">
              <h2 className="title">{t("congratulations")}</h2>
              <span className="modal-body-text">
              {t("invoice_congrats_modal")}
              </span>
            </div>
          </div>
        ) : (
          <div className="modal-custom-body">
            <div className="modal-text-wrapper">
              <h2 className="title">{t("invoice_modal_data_processing")}</h2>
              <span className="modal-body-caption">
                {t("invoice_modal_wait_for_results")}
              </span>
              <ProgressBarCustom progress={props?.progress} />
              <span className="progressbar-caption">{t("invoice_modal_few_seconds")}</span>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default ModalCustom;

import { create } from "@mui/material/styles/createTransitions";
import { createSlice } from "@reduxjs/toolkit";


const profileImageSlice = createSlice({
    name:'profileImage',
    initialState: "",
    reducers: {
        setProfileImage: (state, action) => {
            return action.payload;
        }
    }
})

export const {setProfileImage} = profileImageSlice.actions;
export default profileImageSlice.reducer
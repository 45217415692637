import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import PersonalInfo from "../../components/PersonalInfo";
import AccountSetting from "../../components/common/AccountSetting";
import PrivacySecurity from "../../components/PrivacySecurity";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  getprofileInfo,
  updateuserInfo,
  updateProfileInfo,
} from "../../services/api";
import { Notification } from "../../utils/Notification";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { registered, registeredArabic } from "../../utils/SetupDropdown";
import { useTracking } from "react-tracking";

import ButtonSpinnerCustom from "../../components/common/Button/ButtonSpinnerCustom";
import { Dialog, DialogTitle, DialogContent} from '@mui/material';
import  CloseIcon from '@mui/icons-material/Close';
import { SuccessCheckmark, WarningIcon, EncircledCross, SuccessTickIcon, EncircledCrossExit } from "../../assets/SVG/svg";
import { getProfileCompletionStatus, profileCompletionCheck } from "../../services/ModuleApi";




export const ProfileSetting = (props) => {
  const {t} = useTranslation()
  const profileImageFile = useSelector((state)=>state.profileImage)

  const {trackEvent} = useTracking()



          // Toast modal pop up:
          const [showToastModal, setShowToastModal] = useState(false);
          const handleShowToastModal = () => {
            setShowToastModal(true)
          };
          const handleCloseToastModal = () => {setShowToastModal(false); props?.handleClose();}
  
          const [signOffErrorMsg, setSignOffErrorMsg] = useState({
            variant: "warning",
            message: ""
          })

          useEffect(()=>{ 
            if (signOffErrorMsg.message !=""){
              handleShowToastModal()
            }
          }, [signOffErrorMsg])

  const [values, setValues] = useState(props?.profiles[0]);
  const [select, setSelect] = useState(false);
  const changeInfo = useMutation(updateuserInfo);
  const updateProfile = useMutation(updateProfileInfo);
  const [submitError, setSubmitError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const profileSettingModal = useSelector((state)=>state.profileSettingModal)
  const [isLoading, setIsLoading] = useState(false);

  const { data } = useQuery(["profile setting"], getprofileInfo, {
    refetchOnWindowFocus: false,
    retry: false,
  });
  const [notification, setNotification] = useState({
    open: false,
    variant: "",
    message: "",
  });
  const setNotifications = (open = false, variant = "success", msg ="") => {
    setNotification({ open: open, variant: variant, message: msg });
  };
  const errorSetter = (val, error_msg="") => {
    //console.log("error setter = ", val) // either true or false -> true = error
    setSubmitError(val);
    setErrorMsg(error_msg != "" ? error_msg : t("form_incomplete"))
  };
  const [user, setUser] = useState({
    first_name: data?.data?.object?.first_name,
    last_name: data?.data?.object?.last_name,
    password: undefined,
    email: data?.data?.object?.email,
    phone: data?.data?.object?.phone,
  });

  // this use effect code snippet is causing the edited values to reset 

  // useEffect(() => {
  //   setValues(props?.profiles[0]);
  // }, [props?.profiles]);


  useEffect(() => {
    setUser({
      first_name: data?.data?.object?.first_name,
      last_name: data?.data?.object?.last_name,
      password: undefined,
      email: data?.data?.object?.email,
      phone: data?.data?.object?.phone,
    });
  }, [data]);
  const handleUser = (val, indicator) => {
    //console.log("indicator", indicator)

    if (indicator === "password" && val === "") {
      setUser((curr) => ({
        ...curr,
        [indicator]: undefined,
      }));
    } 

    if (indicator === "confirm_password" && val === "") {
      setUser((curr) => ({
        ...curr,
        [indicator]: undefined,
      }));
    } 


    // else if (indicator === "profile_image") {
    //   var profile_img_object = JSON.parse(val)
    //   const formData = new FormData();
    //   formData.append('profile_image', profile_img_object);
    //   setUser((curr) => ({
    //     ...curr,
    //     //[indicator]: val,
    //     ...formData
    //   }));
    // }

    else {
      setUser((curr) => ({
        ...curr,
        [indicator]: val,
      }));
    }

    
  };

  const handleValue = (val, indicator) => {
    if (indicator === "country") {
      if (val === "UAE") {
        setValues((curr) => ({
          ...curr,
          city: "Abu Dhabi",
        }));
      } else {
        setValues((curr) => ({
          ...curr,
          city: "Riyadh",
        }));
      }
    }

    // if (indicator === "registered"){
    //   //let registered_temp = registered
    //   console.log("reg vals=", val)
    // }
    setValues((curr) => ({
      ...curr,
      [indicator]: val,
    }));
  };

  const dispatch = useDispatch()

  const profileStatusObj = useSelector((state)=>state.profileCompletion)

  const profileCall = () => {
    //console.log('triggered');
    updateProfile.mutate(
      {
        values: values,
        profile: localStorage.getItem("profileid"),
      },

      {
        onSuccess: (data, variables, context) => {
          props?.refetch();
          setIsLoading(false);
          // setNotifications({
          //   open: true,
          //   variant: "success",
          //   message: "Profile updated successfully",
          // });
          //console.log("profile updated", data?.data?.object?.profile_complete)
          const prevProfileStatus = profileStatusObj.profileStatus

          const profileCompleteCheck = profileCompletionCheck(dispatch,data?.data?.object?.profile_complete, localStorage.getItem("profileid"), prevProfileStatus)

          if (profileCompleteCheck?.openUpdateDialog === true){
            //console.log("open update dialog")
            setSignOffErrorMsg({variant: "success", message: t("profile_updated")})
          }
          else {
            props?.handleClose();
          }

          //getProfileCompletionStatus(dispatch, localStorage.getItem("profileid"), prevProfileStatus)
          
          //props?.handleClose();
        },
        onError: (error, variables, context) => {
          setIsLoading(false);
          //console.log(error);
          trackEvent({
            event_type: "error", 
            event_name:"Failed to update user profile", 
            event_data:{
              error: `${error}`
            }
          })
        },
      }
    );
  };

  function filterEmptyStringProps(obj) {
    // Iterate over the object's properties
    for (const [key, value] of Object.entries(obj)) {
      // Check if the value is an empty string or contains only whitespace
      if (typeof value === "string" && value.trim() === "") {
        // Remove the property from the object
        delete obj[key];
      }
    }
    
    return obj;
  }
  
  const profileUpdateCall = () => {
    setIsLoading(true)
    if (submitError) {
      setNotifications(
        true,
        "warning",
        `${errorMsg}`,
      );
    setIsLoading(false)
    } else {
      // setSignOffErrorMsg({variant: "success", message: t("profile_updated")})

      //const formData = new FormData();

      //if (user.hasOwnProperty("profile_image")){
        
      // for (const prop in user) {
        //   if (user.hasOwnProperty(prop)) {

        //     if(prop === "profile_image"){
        //      // console.log("image file redux: ",profileImageFile)
        //       //console.log("img prop found", user[prop])
        //       formData.append("profile_image", profileImageFile)
        //     }
        //     else{
        //     formData.append(prop, user[prop]);
        //     }
            
        //   }
        // }

      //}

      const userDataTemp = filterEmptyStringProps(user)
      //console.log("user personal = ", userDataTemp)

      changeInfo.mutate(
        {
          //formData,
          //user, // legacy object
          user: userDataTemp // new object with only non-empty strings
        },

        {
          onSuccess: (data, variables, context) => {
            // console.log("SUCCESSFUL")
            //setTimeout(()=>{
            profileCall();
            //}, 250)
          },
          onError: (error, variables, context) => {
            setSignOffErrorMsg({variant: "error", message: t("error_general")})
            //console.log(error);
            setIsLoading(false);
            trackEvent({
              event_type: "error", 
              event_name:"Failed to update user profile", 
              event_data:{
                error: `${error}`
              }
            })
          },
        }
      );
    }
  };

  // renders when profile icon is accessed
  return (<>


  
        <Modal
        show={true}
        onHide={props?.handleClose}
        centered
        className="Modal-Account-Setting"
        size="lg"
      >

        <Modal.Header 
        style={{display:"flex", justifyContent:"space-between"}}
        closeButton>

          <Modal.Title style={{width:"92%"}}>{t("option_edit_profile")}</Modal.Title>


        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey={`${ profileSettingModal.tab != "" ? profileSettingModal.tab : "PersonalInfo"}`}
            id="uncontrolled-tab-example"
            onSelect={() => {
              setSelect(!select);
            }}
          >
            <Tab eventKey="PersonalInfo" title={t("acc_set_tab_option1")}>
              <PersonalInfo
                user={user}
                user_img
                handleValue={handleUser}
                name={values?.name}
              />
            </Tab>
            <Tab eventKey="AccountSettings" title={t("acc_set_tab_option2")}>
              <AccountSetting
                values={values}
                handleValue={handleValue}
                select={select}
              />
            </Tab>
            <Tab eventKey="PrivacySecurity" title={t("acc_set_tab_option3")}>
              <PrivacySecurity
                handleValue={handleUser}
                values={user}
                errorSetter={errorSetter}
              />
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="secondarybtn btn-common-primary simpla-btn"
            onClick={props?.handleClose}
          >
            {t("close")}
          </Button>
          <Button
                style={{ background: '#FF7420', borderRadius: '20px', border: 'none', height: '40px', width: '180px' }}
                className=""
                onClick={profileUpdateCall}
                disabled= {isLoading}
              >
                {isLoading ? (
                <>
                  <ButtonSpinnerCustom 
                      style={{height:"1.5rem", width:"1.5rem", marginTop:"2px"}}/> 
                </>
            ) : (
                t("save")
            )}
              </Button>
        </Modal.Footer>
      </Modal>   

    <div>
   
      {updateProfile?.isLoading && (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">{t("loading")}</span>
        </Spinner>
      )}

      {notification.open && (
        <Notification
          open={notification.open}
          variant={notification.variant}
          message={notification.message}
          setNotifications={setNotifications}
        />
      )}
    </div>  

              {/* warning modal */}
<Dialog
  open={showToastModal}
  onClose={handleCloseToastModal}
  fullWidth
  maxWidth="sm"

  PaperProps={{
    sx: {
      minHeight: "max-content",
      borderRadius: "15px",
      background: "#fdfbf5",


    }
  }}
>
  <DialogTitle>

            <div className="" 
         style={{ 
            position: 'absolute', 
            top: "1.5%", 
            right: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "" : "1.5%"}`, 
            left:  `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "1.5%" : ""}`, 
            display:"flex", 
            justifyContent:"center", 
            alignItems:"center", 
            cursor:"pointer" 
            }}>

      <div 
          onClick={() => { setShowToastModal(false); props?.handleClose();}} 
          style={{ marginTop: "3px", cursor: 'pointer', }} >
        <EncircledCrossExit />        
       </div>

      </div>
  </DialogTitle>

  <DialogContent>

    <div style={{padding:"5%"}}>

    {/* <h6 className="more-uses-heading" style={{ fontWeight: "bold", fontFamily: "karla", textAlign: "center" }}>
      {t("in_touch_modal")}
    </h6> */}

    <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}> {signOffErrorMsg.variant === "success" && <SuccessTickIcon/> }  {signOffErrorMsg.variant === "warning" && <WarningIcon/> }  {signOffErrorMsg.variant === "error" && <EncircledCross/>}</div>

    <p style={{ fontFamily: 'karla', fontWeight: 600, textAlign: 'center', lineHeight:"20px", marginTop:"15px" }}>
      {signOffErrorMsg.message}
    </p>

    </div>


  </DialogContent>


</Dialog>
  </>

  );
};

import React from 'react';

const RadioButton = ({ label,options, selectedOption, onChange }) => {
  return (
    <div className='s-mb-24 radio-custom-style'>
              <p className='s-mb-12'>{label}</p>
              <div className='form-check"' >
      {options.map((option) => (
        <label key={option.value} className="form-check-label me-3">
          <input className="form-check-input me-2"
            type="radio"
            name={`radioButton_${label}`} 
            value={option.value}
            checked={selectedOption == option.value}
            onChange={onChange}
          />
          {option.label}
        </label>
      ))}
        </div>
    </div>
  );
};

export default RadioButton;

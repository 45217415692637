// TourContext.js
import { Box, Checkbox, Dialog, DialogContent, Typography,Button as MuiButton } from "@mui/material";
import React, { createContext, useContext, useRef, useState } from "react";
import PrimaryButton from "../Button/PrimaryButton";
import { useNavigate, useNavigation } from "react-router-dom";
import { SERVICES } from "../../../utils/ConstantSettings";
import axios from "axios";

import './TourContent.css'
import { useTracking } from "react-tracking";
import { useTranslation } from "react-i18next";

const TourContext = createContext();

export const useTour = () => {
  return useContext(TourContext);
};

export const TourProvider = ({ children }) => {

  const {trackEvent} = useTracking()
 
  const [isChecked,setIsChecked]=useState(false);
  const [open,setOpen]=useState(false);
  const [toggleActive,setToggleActive]=useState(localStorage.getItem('i18nextLng')?.toString() === "ar" ? true : false);
  const [tourState, setTourState] = useState({
    currentStep: 1,
    isTourActive: false,
  });
  const setTourJump =(val) => {
    setTourState({
      currentStep: val,
      isTourActive: true,
    });
 };

  const startTour = () => {
    setTourState({
      currentStep: 0,
      isTourActive: true,
    });
  };


  const nextStep = () => {
    setTourState((prevState) => ({
      ...prevState,
      currentStep: prevState.currentStep + 1,
    }));
  };
  const prevStep = () => {
    setTourState((prevState) => ({
      ...prevState,
      currentStep: prevState.currentStep - 1,
    }));
  };
  const endTour = () => {
    setTourState({
      currentStep: 0,
      isTourActive: false,
    });

    if(!localStorage.getItem('show-disclaimer')){
      setOpen(true);

    }
  };

  const disclaimerRef=useRef();

 
  const handleScroll=(e)=>{
    //setToggleActive(false)
   
    if(e.target.scrollTop>=500){
        setToggleActive(true);
    }
  }

  const makeStatusChangeCall=()=>{
    axios.post(`${SERVICES.apiBaseUrl}/user/update_disclaimer`,{
    
    },{
      headers:{
        "Authorization":`Bearer ${localStorage.getItem('token')}`
      }
    }).then((res)=>{
      
      localStorage.setItem('show-disclaimer','false');
      setOpen(false);
      
    }).catch((e)=>{
      //console.log(e)
      trackEvent({
        event_type: "error", 
        event_name:"Failed to set disclaimer status", 
        event_data:{
          error: `${e}`
        }
      })
    })
  }

  const {t} = useTranslation();

  return (
    <TourContext.Provider
      value={{
        tourState,
        startTour,
        nextStep,
        endTour,
        prevStep,
        setTourJump
      }}
    >
      <Dialog open={open}   sx={{"& .MuiPaper-root":{borderRadius:'16px',background:'#faf5e1',direction: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "rtl" : "ltr"}`,minWidth:{xs:'94vw',md:'850px'},minHeight:'550px'}}} >
    <Box sx={{marginTop:'1.5%', display:'flex',justifyContent:'center'}} >
                    <h4 variant='body1' style={{fontWeight:700,textAlign:'center'}} >  {t("disclaimer_for_simpla")}
                    </h4>
                   
                </Box>
                
              <DialogContent sx={{maxHeight:'400px', direction: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "rtl" : "ltr"}`}} onScroll={handleScroll} ref={disclaimerRef}   >
              
                <Box mt={3} >
                      <Typography variant="body2" sx={{textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}} > 
                      {t("disclaimer_body")}
                      </Typography>
                </Box>
                <Box mt={3} >
                    <Typography sx={{textDecoration:'underline',textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}} > {t("disclaimer_advise")} </Typography>
                    <Typography variant='body2' sx={{textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}}  >{t("disclaimer_advise_body")}
</Typography>
                </Box>
                <Box mt={3} >
                    <Typography sx={{textDecoration:'underline',textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}} > {t("disclaimer_analyse")} </Typography>
                    <Typography variant='body2' sx={{textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}}  >
                      {t("disclaimer_analyse_body")}
                    </Typography>
                </Box>
                <Box mt={3} >
                    <Typography sx={{textDecoration:'underline',textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}} > {t("disclaimer_process")} </Typography>
                    <Typography variant='body2' sx={{textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}}  >{t("disclaimer_process_body")}</Typography>
                </Box>
                <Box mt={3} >
                    <Typography sx={{textDecoration:'underline',textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}} >{t("disclaimer_general")} </Typography>
                    <Typography variant='body2' sx={{textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}}  > {t("disclaimer_general_body")}

</Typography>
                </Box>
                <Box mt={3} >
                    <Typography sx={{textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}} > {t("help_phrase")} <a> {t("help_email")} </a> </Typography>
              
                </Box>
              </DialogContent>
              <Box className='disclaimer-checkbox-and-button'  mt={3} sx={{  display:'flex',gap:'0.5rem',width:'100%',alignItems:'center'}} >
                <Box  sx={{display:'flex',gap:'0.1rem',alignItems:'center', fontWeight:500}} >
                <Checkbox
                    disabled={toggleActive===true ? false :true}
                    sx={{color:'#FF7420','& .MuiSvgIcon-root':{color:'#FF7420'}}}
                      checked={isChecked===true ? true :false}
                      onClick={()=>setIsChecked(!isChecked)}
                    />
                    
                    {t("skip_disclaimer")}                   
                </Box>
                <MuiButton className="disclaimer-agree-button" disabled={toggleActive===false ? true :false} disableElevation disableFocusRipple disableRipple disableTouchRipple variant='contained' sx={{  letterSpacing:'-0.645888px !important', fontSize:'20px', "&:disabled":{background:'white !important',color:'black !important'}, borderRadius:'18px', fontFamily: 'Karla, sans-serif', width:'210px',height:'40px', textTransform:'none', background:toggleActive==false ? 'white' :'#FF7420', color:toggleActive==false ? 'black' :'white','&:hover':{background:toggleActive==false ? 'white' :'#FF7420'}}}   onClick={(e) => {
                if(isChecked===true){
                      makeStatusChangeCall();
                }
                else{
                  localStorage.setItem('show-disclaimer','false');
                  setOpen(false);
                }
              
              
              
           
              
              }} >
                    {t("understand")}
                </MuiButton>
              {/* <PrimaryButton
              buttonText={
               'I understand'
              }
              
              styleclass={`${toggleActive === false  ? 'primarybtn btn-common-primary mt-auto disclaimerbtn' :''}`}
              icon={undefined}
              
              type={"submit"}
              hasIcon={false}
              size={undefined}
              disabled={toggleActive==false ? true :false}
              onClick={(e) => {
                if(isChecked===true){
                      makeStatusChangeCall();
                }
                else{
                  localStorage.setItem('show-disclaimer','false');
                  setOpen(false);
                }
              
              
              
           
              
              }}
            /> */}
              </Box>
    </Dialog>
      {children}
    </TourContext.Provider>
  );
};

function trimArrayObjects(arr) {
  return arr.map(obj => {
    const trimmedObj = {};

    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        trimmedObj[key] = `${obj[key]}`.trim();
      }
    }

    return trimmedObj;
  });
}

export const location = trimArrayObjects([
  { label: "UAE", value: "UAE", labelArabic: "الإمارات", valueArabic: "الإمارات" },
  { label: "KSA", value: "KSA", labelArabic: "المملكة العربية السعودية", valueArabic: "المملكة العربية السعودية" },
]);


export const uaeCity = trimArrayObjects([
  { label: "Select a city", value: "Select a city", labelArabic: "اختر مدينة", valueArabic: "اختر مدينة" },
  { label: "Abu Dhabi", value: "Abu Dhabi", labelArabic: "أبو ظبي", valueArabic: "أبو ظبي" },
  { label: "Dubai", value: "Dubai", labelArabic: "دبي", valueArabic: "دبي" },
  { label: "Sharjah", value: "Sharjah", labelArabic: "الشارقة", valueArabic: "الشارقة" },
  { label: "Al Ain", value: "Al Ain", labelArabic: "العين", valueArabic: "العين" },
  { label: "Ajman", value: "Ajman", labelArabic: "عجمان", valueArabic: "عجمان" },
  { label: "Ras Al Khaimah", value: "Ras Al Khaimah", labelArabic: "رأس الخيمة", valueArabic: "رأس الخيمة" },
  { label: "Fujairah", value: "Fujairah", labelArabic: "الفجيرة", valueArabic: "الفجيرة" },
  { label: "Umm Al Quwain", value: "Umm Al Quwain", labelArabic: "أم القيوين", valueArabic: "أم القيوين" },
]);
export const saudiCity = trimArrayObjects([
  { label: "Select a city", value: "Select a city", labelArabic: "اختر مدينة", valueArabic: "اختر مدينة" },
  { label: "Riyadh", value: "Riyadh", labelArabic: "الرياض", valueArabic: "الرياض" },
  { label: "Jeddah", value: "Jeddah", labelArabic: "جدة", valueArabic: "جدة" },
  { label: "Mecca", value: "Mecca", labelArabic: "مكة المدينة", valueArabic: "مكة المدينة" },
  { label: "Medina", value: "Medina", labelArabic: "المدينة المنورة", valueArabic: "المدينة المنورة" },
  { label: "Dammam", value: "Dammam", labelArabic: "الدمام", valueArabic: "الدمام" },
  { label: "Taif", value: "Taif", labelArabic: "الطائف", valueArabic: "الطائف" },
  { label: "Buraidah", value: "Buraidah", labelArabic: "بريدة", valueArabic: "بريدة" },
  { label: "Tabuk", value: "Tabuk", labelArabic: "تبوك", valueArabic: "تبوك" },
  { label: "Al Khobar", value: "Al Khobar", labelArabic: "الخبر", valueArabic: "الخبر" },
]);

export const Tax = trimArrayObjects([
  { label: 'Corporate Tax', value: 'Corporate Tax', labelArabic: 'ضريبة الشركات', valueArabic: 'ضريبة الشركات' },
  { label: 'VAT', value: 'VAT', labelArabic: 'ضريبة القيمة المضافة', valueArabic: 'ضريبة القيمة المضافة' },
  { label: 'Excise Tax', value: 'Excise Tax', labelArabic: 'ضريبة السلع الإنتقائية', valueArabic: 'ضريبة السلع الإنتقائية' },
  { label: 'Municipality Fees', value: 'Municipality Fees', labelArabic: 'رسوم البلدية', valueArabic: 'رسوم البلدية' },
  { label: 'Tourism Dirham', value: 'Tourism Dirham', labelArabic: 'درهم السياحة', valueArabic: 'درهم السياحة' },
]);

export const businessStatus = trimArrayObjects([
  { label: "Pre-formation", value: "Pre-formation", labelArabic: "ما قبل التشكيل", valueArabic: "ما قبل التشكيل" },
  { label: "Start-up", value: "Start-up", labelArabic: "شركة ناشئة ", valueArabic: "شركة ناشئة" },
  { label: "Well-established brands", value: "Well established brands", labelArabic: "العلامات التجارية الراسخة", valueArabic: "العلامات التجارية الراسخة" },
  { label: "Multi-National Enterprise", value: "Multi National Enterprise", labelArabic: "شركة متعددة الجنسيات", valueArabic: "شركة متعددة الجنسيات" },
  { label: "Consultancy", value: "Consultancy", labelArabic: "استشارية", valueArabic: "استشارية" },
  { label: "Family business", value: "Family business", labelArabic: "شركة عائلية", valueArabic: "شركة عائلية "},
  { label: "Property owner", value: "Property owner", labelArabic: "مالك العقار" ,valueArabic: "مالك العقار"},
  { label: "Small Medium Enterprise", value: "Small Medium Enterprise", labelArabic: "الشركات الصغيرة والمتوسطة", valueArabic: "الشركات الصغيرة والمتوسطة" },
  { label: "Government owned", value: "Government owned", labelArabic: "مملوكة للحكومة" ,valueArabic: "مملوكة للحكومة"},
]);

export const legalType = trimArrayObjects([
  { label: "Limited Liability Company", value: "Limited Liability Company", labelArabic: "شركة ذات مسؤولية محدودة", valueArabic: "شركة ذات مسؤولية محدودة" },
  { label: "Public Shareholding company", value: "Public Shareholding company", labelArabic: "شركة مساهمة عامة", valueArabic: "شركة مساهمة عامة" },
  { label: "Sole Proprietorship", value: "Sole Proprietorship", labelArabic: "ملكية فردية", valueArabic: "ملكية فردية" },
  { label: "Partnership", value: "Partnership", labelArabic: "شراكة", valueArabic: "شراكة" },
  { label: "Branch of a Foreign Company", value: "Branch of a Foreign Company", labelArabic: "فرع لشركة أجنبية", valueArabic: "فرع لشركة أجنبية" },
  { label: "Representative Office", value: "Representative Office", labelArabic: "مكتب تمثيلي ", valueArabic:"مكتب تمثيلي" },
  { label: "Civil Company", value: "Civil Company", labelArabic: "شركة مدنية", valueArabic: "شركة مدنية" },
  { label: "Joint Venture", value: "Joint Venture", labelArabic: "مشروع مشترك", valueArabic: "مشروع مشترك" },
  { label: "Holding Company", value: "Holding Company", labelArabic: "شركة قابضة", valueArabic: "شركة قابضة" },
  { label: "Sole Proprietorship LLC", value: "Sole Proprietorship LLC", labelArabic: "ملكية فردية ذ.م.م", valueArabic: "ملكية فردية ذ.م.م" },
  { label: "Non-resident legal person", value: "Non-resident legal person", labelArabic: "شخص اعتباري غير مقيم", valueArabic: "شخص اعتباري غير مقيم "},
]);
export const industry = trimArrayObjects([
  { label: "E-commerce", value: "E-commerce", labelArabic: "التجارة الإلكترونية", valueArabic: "التجارة الإلكترونية" },
  { label: "Construction and Real Estate", value: "Construction and Real Estate", labelArabic: "البناء والعقارات", valueArabic: "البناء والعقارات" },
  { label: "Tourism and Hospitality", value: "Tourism and Hospitality", labelArabic: "السياحة والضيافة", valueArabic: "السياحة والضيافة" },
  { label: "Financial Services ", value: "Financial Services ", labelArabic: "الخدمات المالية", valueArabic: "الخدمات المالية" },
  { label: "Retail", value: "Retail", labelArabic: "بيع بالتجزئة" ,valueArabic: "بيع بالتجزئة" },
  { label: "Manufacturing", value: "Manufacturing", labelArabic: "تصنيع", valueArabic: "تصنيع" },
  { label: "Logistics and Transportation", value: "Logistics and Transportation", labelArabic: "اللوجستية والنقل", valueArabic: "اللوجستية والنقل" },
  { label: "Healthcare", value: "Healthcare", labelArabic: "الرعاية الصحية", valueArabic: "الرعاية الصحية" },
  { label: "Education", value: "Education", labelArabic: "التعليم", valueArabic: "التعليم" },
  { label: "Information and Communication Technology (ICT)", value: "Information and Communication Technology (ICT)", labelArabic: "تكنولوجيا المعلومات والاتصالات", valueArabic: "تكنولوجيا المعلومات والاتصالات" },
  { label: "Oil and gas, mining, Petro chemicals etc", value: "Oil and gas, mining, Petro chemicals etc", labelArabic: "النفط والغاز والتعدين والبتروكيماويات الخ", valueArabic: "النفط والغاز والتعدين والبتروكيماويات الخ" },
  { label: "Beauty and personal care industry", value: "Beauty and personal care industry", labelArabic: "التجميل والعناية الشخصية", valueArabic: "التجميل والعناية الشخصية" },
  { label: "Trading", value: "Trading", labelArabic: "تجارة", valueArabic: "تجارة" },
]);

export const registered = trimArrayObjects([
  { label: "Corporate tax", value: "Corporate tax", labelArabic: "ضريبة الشركات", valueArabic: "ضريبة الشركات" },
  { label: "VAT", value: "VAT", labelArabic: "ضريبة القيمة المضافة", valueArabic: "ضريبة القيمة المضافة" },
  { label: "WHT", value: "WHT", labelArabic: "الضريبة المقتطعة", valueArabic: "الضريبة المقتطعة" },
  { label: "Zakat", value: "Zakat", labelArabic: "الزكاة", valueArabic: "الزكاة" },
  { label: "Excise tax", value: "Excise tax", labelArabic: "الضريبة الانتقائية", valueArabic: "الضريبة الانتقائية" },
  { label: "Municipality Fees", value: "Municipality Fees", labelArabic: "رسوم البلدية", valueArabic: "رسوم البلدية" },
  { label: "Tourism Dirham", value: "Tourism Dirham", labelArabic: "درهم السياحة", valueArabic: "درهم السياحة" },
]);

export const registeredArabic = trimArrayObjects([
  { labelEnglish: "Corporate tax", valueEnglish: "Corporate tax", label: "ضريبة الشركات", value: "ضريبة الشركات" },
  { labelEnglish: "VAT", valueEnglish: "VAT", label: "ضريبة القيمة المضافة", value: "ضريبة القيمة المضافة" },
  { labelEnglish: "WHT", valueEnglish: "WHT", label: "الضريبة المقتطعة", value: "الضريبة المقتطعة" },
  { labelEnglish: "Zakat", valueEnglish: "Zakat", label: "الزكاة", value: "الزكاة" },
  { labelEnglish: "Excise tax", valueEnglish: "Excise tax", label: "الضريبة الانتقائية", value: "الضريبة الانتقائية" },
  { labelEnglish: "Municipality Fees", valueEnglish: "Municipality Fees", label: "رسوم البلدية", value: "رسوم البلدية" },
  { labelEnglish: "Tourism Dirham", valueEnglish: "Tourism Dirham", label: "درهم السياحة", value: "درهم السياحة" },
]);

export const jurisdiction = trimArrayObjects([
  { label: "Does not Apply", value: "Does not Apply", labelArabic: "لا ينطبق", valueArabic: "لا ينطبق" },
  { label: "Free Trade Zone of Khalifa Port", value: "Free Trade Zone of Khalifa Port", labelArabic: "منطقة التجارة الحرة بميناء خليفة", valueArabic: "منطقة التجارة الحرة بميناء خليفة" },
  { label: "Abu Dhabi Airport Free Zone", value: "Abu Dhabi Airport Free Zone", labelArabic: "المنطقة الحرة بمطار أبوظبي", valueArabic: "المنطقة الحرة بمطار أبوظبي" },
  { label: "Khalifa Industrial Zone", value: "Khalifa Industrial Zone", labelArabic: "منطقة خليفة الصناعية" , valueArabic: "منطقة خليفة الصناعية" },
  { label: "Jebel Ali Free Zone (North-South)", value: "Jebel Ali Free Zone (North-South)", labelArabic: "المنطقة الحرة بجبل علي (الشمال والجنوب)", valueArabic: "المنطقة الحرة بجبل علي (الشمال والجنوب)" },
  { label: "Dubai Cars and Automotive Zone (DUCAMZ)", value: "Dubai Cars and Automotive Zone (DUCAMZ)", labelArabic: "منطقة دبي للسيارات (دوكامز)", valueArabic: "منطقة دبي للسيارات (دوكامز)" },
  { label: "Dubai Textile City", value: "Dubai Textile City", labelArabic: "مدينة دبي للنسيج", valueArabic: "مدينة دبي للنسيج" },
  { label: "Free Zone Area in Al Quoz", value: "Free Zone Area in Al Quoz", labelArabic: "منطقة حرة في القوز", valueArabic: "منطقة حرة في القوز" },
  { label: "Free Zone Area in Al Qusais", value: "Free Zone Area in Al Qusais", labelArabic: "منطقة حرة في القصيص", valueArabic: "منطقة حرة في القصيص" },
  { label: "Dubai Aviation City", value: "Dubai Aviation City", labelArabic: "مدينة دبي للطيران", valueArabic: "مدينة دبي للطيران" },
  { label: "Dubai Airport Free Zone", value: "Dubai Airport Free Zone", labelArabic: "المنطقة الحرة بمطار دبي", valueArabic: "المنطقة الحرة بمطار دبي "},
  { label: "Hamriyah Free Zone", value: "Hamriyah Free Zone", labelArabic: "المنطقة الحرة بالحمرية", valueArabic: "المنطقة الحرة بالحمرية" },
  { label: "Sharjah Airport International Free Zone", value: "Sharjah Airport International Free Zone", labelArabic: "المنطقة الحرة بمطار الشارقة الدولي", valueArabic: "المنطقة الحرة بمطار الشارقة الدولي" },
  { label: "Ajman Free Zone", value: "Ajman Free Zone", labelArabic: "المنطقة الحرة بعجمان", valueArabic: "المنطقة الحرة بعجمان" },
  { label: "Umm Al Quwain Free Trade Zone in Ahmed Bin Rashid Port", value: "Umm Al Quwain Free Trade Zone in Ahmed Bin Rashid Port", labelArabic: "منطقة أم القيوين للتجارة الحرة بميناء أحمد بن راشد", valueArabic: "منطقة أم القيوين للتجارة الحرة بميناء أحمد بن راشد" },
  { label: "Umm Al Quwain Free Trade Zone on Sheikh Monhammed Bin ", value: "Umm Al Quwain Free Trade Zone on Sheikh Monhammed Bin ", labelArabic: "منطقة أم القيوين للتجارة الحرة على شارع الشيخ محمد بن زايد," ,  valueArabic: "منطقة أم القيوين للتجارة الحرة على شارع الشيخ محمد بن زايد" },
  { label: "Zayed Road", value: "Zayed Road", labelArabic: "شارع الشيخ زايد", valueArabic: "شارع الشيخ زايد" },
  { label: "RAK Free Trade Zone", value: "RAK Free Trade Zone", labelArabic: "منطقة رأس الخيمة للتجارة الحرة", valueArabic: "منطقة رأس الخيمة للتجارة الحرة "},
  { label: "RAK Maritime City Free Zone", value: "RAK Maritime City Free Zone", labelArabic: "المنطقة الحرة لمدينة رأس الخيمة البحرية", valueArabic: "المنطقة الحرة لمدينة رأس الخيمة البحرية "},
  { label: "RAK Airport Free Zone", value: "RAK Airport Free Zone", labelArabic: "المنطقة الحرة بمطار رأس الخيمة", valueArabic: "المنطقة الحرة بمطار رأس الخيمة" },
  { label: "Fujairah Free Zone", value: "Fujairah Free Zone", labelArabic: "المنطقة الحرة بالفجيرة", valueArabic: "المنطقة الحرة بالفجيرة" },
  { label: "FOIZ (Fujairah Oil Industry Zone)", value: "FOIZ (Fujairah Oil Industry Zone)", labelArabic: "FOIZ (منطقة الفجيرة لصناعة النفط)", valueArabic: "FOIZ (منطقة الفجيرة لصناعة النفط)" },
]);

  export const years=()=>{
    const currentYear = new Date().getFullYear();
  const startYear = 1950;
  const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => {
    const year = startYear + index;
    return { label: year.toString(), value: year.toString(), valueArabic: year.toString() };
  });

  return [...years ];
  }
  export const notApplicable = trimArrayObjects([
    { label: "N/A", value: "N/A" },
  ]);
  
  export const all_countries = trimArrayObjects([
    { code:"AE", phone:"971", label: "UAE", value: "UAE", labelArabic: "الإمارات", valueArabic: "الإمارات" },
    { code:"SA", phone:"966", label: "KSA", value: "KSA", labelArabic: "المملكة العربية السعودية", valueArabic: "المملكة العربية السعودية" },

    {
      code: 'BH',
      label: 'Bahrain',
      phone: '973',
      labelArabic: 'البحرين',
      valueArabic: 'البحرين',
      value: 'Bahrain'
    },
    {
      code: 'JO',
      label: 'Jordan',
      phone: '962',
      labelArabic: 'الأردن',
      valueArabic: 'الأردن',
      value: 'Jordan'
    },
    {
      code: 'OM',
      label: 'Oman',
      phone: '968',
      labelArabic: 'عمان',
      valueArabic: 'عمان',
      value: 'Oman'
    },
    {
      code: 'QA',
      label: 'Qatar',
      phone: '974',
      labelArabic: 'قطر',
      valueArabic: 'قطر',
      value: 'Qatar'
    },
    {
      code: 'EG',
      label: 'Egypt',
      phone: '20',
      labelArabic: 'مصر',
      valueArabic: 'مصر',
      value: 'Egypt'
    },
    

    {
      code: 'US',
      label: 'United States',
      phone: '1',
      labelArabic: 'الولايات المتحدة',
      valueArabic: 'الولايات المتحدة',
      value: 'United States'
    },
    {
      code: 'AD',
      label: 'Andorra',
      phone: '376',
      labelArabic: 'أندورا',
      valueArabic: 'أندورا',
      value: 'Andorra'
    },
    {
      code: 'AF',
      label: 'Afghanistan',
      phone: '93',
      labelArabic: 'أفغانستان',
      valueArabic: 'أفغانستان',
      value: 'Afghanistan'
    },
    {
      code: 'AG',
      label: 'Antigua and Barbuda',
      phone: '1-268',
      labelArabic: 'أنتيغوا وبربودا',
      valueArabic: 'أنتيغوا وبربودا',
      value: 'Antigua and Barbuda'
    },
    {
      code: 'AI',
      label: 'Anguilla',
      phone: '1-264',
      labelArabic: 'أنغويلا',
      valueArabic: 'أنغويلا',
      value: 'Anguilla'
    },
    {
      code: 'AL',
      label: 'Albania',
      phone: '355',
      labelArabic: 'ألبانيا',
      valueArabic: 'ألبانيا',
      value: 'Albania'
    },
    {
      code: 'AM',
      label: 'Armenia',
      phone: '374',
      labelArabic: 'أرمينيا',
      valueArabic: 'أرمينيا',
      value: 'Armenia'
    },
    {
      code: 'AO',
      label: 'Angola',
      phone: '244',
      labelArabic: 'أنغولا',
      valueArabic: 'أنغولا',
      value: 'Angola'
    },
    {
      code: 'AQ',
      label: 'Antarctica',
      phone: '672',
      labelArabic: 'القارة القطبية الجنوبية',
      valueArabic: 'القارة القطبية الجنوبية',
      value: 'Antarctica'
    },
    {
      code: 'AR',
      label: 'Argentina',
      phone: '54',
      labelArabic: 'الأرجنتين',
      valueArabic: 'الأرجنتين',
      value: 'Argentina'
    },
    {
      code: 'AS',
      label: 'American Samoa',
      phone: '1-684',
      labelArabic: 'ساموا الأمريكية',
      valueArabic: 'ساموا الأمريكية',
      value: 'American Samoa'
    },
    {
      code: 'AT',
      label: 'Austria',
      phone: '43',
      labelArabic: 'النمسا',
      valueArabic: 'النمسا',
      value: 'Austria'
    },
    {
      code: 'AU',
      label: 'Australia',
      phone: '61',
      suggested: true,
      labelArabic: 'أستراليا',
      valueArabic: 'أستراليا',
      value: 'Australia'
    },
    {
      code: 'AW',
      label: 'Aruba',
      phone: '297',
      labelArabic: 'أروبا',
      valueArabic: 'أروبا',
      value: 'Aruba'
    },
    {
      code: 'AX',
      label: 'Alland Islands',
      phone: '358',
      labelArabic: 'جزر أولاند',
      valueArabic: 'جزر أولاند',
      value: 'Alland Islands'
    },
    {
      code: 'AZ',
      label: 'Azerbaijan',
      phone: '994',
      labelArabic: 'أذربيجان',
      valueArabic: 'أذربيجان',
      value: 'Azerbaijan'
    },
    {
      code: 'BA',
      label: 'Bosnia and Herzegovina',
      phone: '387',
      labelArabic: 'البوسنة والهرسك',
      valueArabic: 'البوسنة والهرسك',
      value: 'Bosnia and Herzegovina'
    },
    {
      code: 'BB',
      label: 'Barbados',
      phone: '1-246',
      labelArabic: 'بربادوس',
      valueArabic: 'بربادوس',
      value: 'Barbados'
    },
    {
      code: 'BD',
      label: 'Bangladesh',
      phone: '880',
      labelArabic: 'بنغلاديش',
      valueArabic: 'بنغلاديش',
      value: 'Bangladesh'
    },
    {
      code: 'BE',
      label: 'Belgium',
      phone: '32',
      labelArabic: 'بلجيكا',
      valueArabic: 'بلجيكا',
      value: 'Belgium'
    },
    {
      code: 'BF',
      label: 'Burkina Faso',
      phone: '226',
      labelArabic: 'بوركينا فاسو',
      valueArabic: 'بوركينا فاسو',
      value: 'Burkina Faso'
    },
    {
      code: 'BG',
      label: 'Bulgaria',
      phone: '359',
      labelArabic: 'بلغاريا',
      valueArabic: 'بلغاريا',
      value: 'Bulgaria'
    },
    {
      code: 'BI',
      label: 'Burundi',
      phone: '257',
      labelArabic: 'بوروندي',
      valueArabic: 'بوروندي',
      value: 'Burundi'
    },
    {
      code: 'BJ',
      label: 'Benin',
      phone: '229',
      labelArabic: 'بنين',
      valueArabic: 'بنين',
      value: 'Benin'
    },
    {
      code: 'BL',
      label: 'Saint Barthelemy',
      phone: '590',
      labelArabic: 'سان بارتيلمي',
      valueArabic: 'سان بارتيلمي',
      value: 'Saint Barthelemy'
    },
    {
      code: 'BM',
      label: 'Bermuda',
      phone: '1-441',
      labelArabic: 'برمودا',
      valueArabic: 'برمودا',
      value: 'Bermuda'
    },
    {
      code: 'BN',
      label: 'Brunei Darussalam',
      phone: '673',
      labelArabic: 'بروناي',
      valueArabic: 'بروناي',
      value: 'Brunei Darussalam'
    },
    {
      code: 'BO',
      label: 'Bolivia',
      phone: '591',
      labelArabic: 'بوليفيا',
      valueArabic: 'بوليفيا',
      value: 'Bolivia'
    },
    {
      code: 'BR',
      label: 'Brazil',
      phone: '55',
      labelArabic: 'البرازيل',
      valueArabic: 'البرازيل',
      value: 'Brazil'
    },
    {
      code: 'BS',
      label: 'Bahamas',
      phone: '1-242',
      labelArabic: 'الباهاما',
      valueArabic: 'الباهاما',
      value: 'Bahamas'
    },
    {
      code: 'BT',
      label: 'Bhutan',
      phone: '975',
      labelArabic: 'بوتان',
      valueArabic: 'بوتان',
      value: 'Bhutan'
    },
    {
      code: 'BV',
      label: 'Bouvet Island',
      phone: '47',
      labelArabic: 'جزيرة بوفيه',
      valueArabic: 'جزيرة بوفيه',
      value: 'Bouvet Island'
    },
    {
      code: 'BW',
      label: 'Botswana',
      phone: '267',
      labelArabic: 'بتسوانا',
      valueArabic: 'بتسوانا',
      value: 'Botswana'
    },
    {
      code: 'BY',
      label: 'Belarus',
      phone: '375',
      labelArabic: 'بيلاروسيا',
      valueArabic: 'بيلاروسيا',
      value: 'Belarus'
    },
    {
      code: 'BZ',
      label: 'Belize',
      phone: '501',
      labelArabic: 'بليز',
      valueArabic: 'بليز',
      value: 'Belize'
    },
    {
      code: 'CA',
      label: 'Canada',
      phone: '1',
      suggested: true,
      labelArabic: 'كندا',
      valueArabic: 'كندا',
      value: 'Canada'
    },
    {
      code: 'CC',
      label: 'Cocos (Keeling) Islands',
      phone: '61',
      labelArabic: 'جزر كوكوس (كيلينغ)',
      valueArabic: 'جزر كوكوس (كيلينغ)',
      value: 'Cocos (Keeling) Islands'
    },
    {
      code: 'CD',
      label: 'Congo, Democratic Republic of the',
      phone: '243',
      labelArabic: 'جمهورية الكونغو الديمقراطية',
      valueArabic: 'جمهورية الكونغو الديمقراطية',
      value: 'Congo, Democratic Republic of the'
    },
    {
      code: 'CF',
      label: 'Central African Republic',
      phone: '236',
      labelArabic: 'جمهورية أفريقيا الوسطى',
      valueArabic: 'جمهورية أفريقيا الوسطى',
      value: 'Central African Republic'
    },
    {
      code: 'CG',
      label: 'Congo, Republic of the',
      phone: '242',
      labelArabic: 'جمهورية الكونغو',
      valueArabic: 'جمهورية الكونغو',
      value: 'Congo, Republic of the'
    },
    {
      code: 'CH',
      label: 'Switzerland',
      phone: '41',
      labelArabic: 'سويسرا',
      valueArabic: 'سويسرا',
      value: 'Switzerland'
    },
    {
      code: 'CI',
      label: "Cote d'Ivoire",
      phone: '225',
      labelArabic: 'ساحل العاج',
      valueArabic: 'ساحل العاج',
      value: "Cote d'Ivoire"
    },
    {
      code: 'CK',
      label: 'Cook Islands',
      phone: '682',
      labelArabic: 'جزر كوك',
      valueArabic: 'جزر كوك',
      value: 'Cook Islands'
    },
    {
      code: 'CL',
      label: 'Chile',
      phone: '56',
      labelArabic: 'تشيلي',
      valueArabic: 'تشيلي',
      value: 'Chile'
    },
    {
      code: 'CM',
      label: 'Cameroon',
      phone: '237',
      labelArabic: 'الكاميرون',
      valueArabic: 'الكاميرون',
      value: 'Cameroon'
    },
    {
      code: 'CN',
      label: 'China',
      phone: '86',
      labelArabic: 'الصين',
      valueArabic: 'الصين',
      value: 'China'
    },
    {
      code: 'CO',
      label: 'Colombia',
      phone: '57',
      labelArabic: 'كولومبيا',
      valueArabic: 'كولومبيا',
      value: 'Colombia'
    },
    {
      code: 'CR',
      label: 'Costa Rica',
      phone: '506',
      labelArabic: 'كوستاريكا',
      valueArabic: 'كوستاريكا',
      value: 'Costa Rica'
    },
    {
      code: 'CU',
      label: 'Cuba',
      phone: '53',
      labelArabic: 'كوبا',
      valueArabic: 'كوبا',
      value: 'Cuba'
    },
    {
      code: 'CV',
      label: 'Cape Verde',
      phone: '238',
      labelArabic: 'الرأس الأخضر',
      valueArabic: 'الرأس الأخضر',
      value: 'Cape Verde'
    },
    {
      code: 'CW',
      label: 'Curacao',
      phone: '599',
      labelArabic: 'كوراساو',
      valueArabic: 'كوراساو',
      value: 'Curacao'
    },
    {
      code: 'CX',
      label: 'Christmas Island',
      phone: '61',
      labelArabic: 'جزيرة الكريسماس',
      valueArabic: 'جزيرة الكريسماس',
      value: 'Christmas Island'
    },
    {
      code: 'CY',
      label: 'Cyprus',
      phone: '357',
      labelArabic: 'قبرص',
      valueArabic: 'قبرص',
      value: 'Cyprus'
    },
    {
      code: 'CZ',
      label: 'Czech Republic',
      phone: '420',
      labelArabic: 'جمهورية التشيك',
      valueArabic: 'جمهورية التشيك',
      value: 'Czech Republic'
    },
    {
      code: 'DE',
      label: 'Germany',
      phone: '49',
      suggested: true,
      labelArabic: 'ألمانيا',
      valueArabic: 'ألمانيا',
      value: 'Germany'
    },
    {
      code: 'DJ',
      label: 'Djibouti',
      phone: '253',
      labelArabic: 'جيبوتي',
      valueArabic: 'جيبوتي',
      value: 'Djibouti'
    },
    {
      code: 'DK',
      label: 'Denmark',
      phone: '45',
      labelArabic: 'الدنمارك',
      valueArabic: 'الدنمارك',
      value: 'Denmark'
    },
    {
      code: 'DM',
      label: 'Dominica',
      phone: '1-767',
      labelArabic: 'دومينيكا',
      valueArabic: 'دومينيكا',
      value: 'Dominica'
    },
    {
      code: 'DO',
      label: 'Dominican Republic',
      phone: '1-809',
      labelArabic: 'جمهورية الدومينيكان',
      valueArabic: 'جمهورية الدومينيكان',
      value: 'Dominican Republic'
    },
    {
      code: 'DZ',
      label: 'Algeria',
      phone: '213',
      labelArabic: 'الجزائر',
      valueArabic: 'الجزائر',
      value: 'Algeria'
    },
    {
      code: 'EC',
      label: 'Ecuador',
      phone: '593',
      labelArabic: 'الإكوادور',
      valueArabic: 'الإكوادور',
      value: 'Ecuador'
    },
    {
      code: 'EE',
      label: 'Estonia',
      phone: '372',
      labelArabic: 'إستونيا',
      valueArabic: 'إستونيا',
      value: 'Estonia'
    },
    {
      code: 'EH',
      label: 'Western Sahara',
      phone: '212',
      labelArabic: 'الصحراء الغربية',
      valueArabic: 'الصحراء الغربية',
      value: 'Western Sahara'
    },
    {
      code: 'ER',
      label: 'Eritrea',
      phone: '291',
      labelArabic: 'إريتريا',
      valueArabic: 'إريتريا',
      value: 'Eritrea'
    },
    {
      code: 'ES',
      label: 'Spain',
      phone: '34',
      labelArabic: 'إسبانيا',
      valueArabic: 'إسبانيا',
      value: 'Spain'
    },
    {
      code: 'ET',
      label: 'Ethiopia',
      phone: '251',
      labelArabic: 'إثيوبيا',
      valueArabic: 'إثيوبيا',
      value: 'Ethiopia'
    },
    {
      code: 'FI',
      label: 'Finland',
      phone: '358',
      labelArabic: 'فنلندا',
      valueArabic: 'فنلندا',
      value: 'Finland'
    },
    {
      code: 'FJ',
      label: 'Fiji',
      phone: '679',
      labelArabic: 'فيجي',
      valueArabic: 'فيجي',
      value: 'Fiji'
    },
    {
      code: 'FK',
      label: 'Falkland Islands (Malvinas)',
      phone: '500',
      labelArabic: 'جزر فوكلاند (مالفيناس)',
      valueArabic: 'جزر فوكلاند (مالفيناس)',
      value: 'Falkland Islands (Malvinas)'
    },
    {
      code: 'FM',
      label: 'Micronesia, Federated States of',
      phone: '691',
      labelArabic: 'ميكرونيزيا، الولايات المتحدة',
      valueArabic: 'ميكرونيزيا، الولايات المتحدة',
      value: 'Micronesia, Federated States of'
    },
    {
      code: 'FO',
      label: 'Faroe Islands',
      phone: '298',
      labelArabic: 'جزر فارو',
      value: 'Faroe Islands',
      valueArabic: 'جزر فارو'
    },    
    {
      code: 'FR',
      label: 'France',
      phone: '33',
      suggested: true,
      labelArabic: 'فرنسا',
      phone_arabic: '33',
      value: 'France'
    },
    {
      code: 'GA',
      label: 'Gabon',
      phone: '241',
      labelArabic: 'الغابون',
      phone_arabic: '241',
      value: 'Gabon'
    },
    {
      code: 'GB',
      label: 'United Kingdom',
      phone: '44',
      labelArabic: 'المملكة المتحدة',
      phone_arabic: '44',
      value: 'United Kingdom'
    },
    {
      code: 'GD',
      label: 'Grenada',
      phone: '1-473',
      labelArabic: 'غرينادا',
      phone_arabic: '1-473',
      value: 'Grenada'
    },
    {
      code: 'GE',
      label: 'Georgia',
      phone: '995',
      labelArabic: 'جورجيا',
      phone_arabic: '995',
      value: 'Georgia'
    },
    {
      code: 'GF',
      label: 'French Guiana',
      phone: '594',
      labelArabic: 'غويانا الفرنسية',
      phone_arabic: '594',
      value: 'French Guiana'
    },
    {
      code: 'GG',
      label: 'Guernsey',
      phone: '44',
      labelArabic: 'غيرنزي',
      phone_arabic: '44',
      value: 'Guernsey'
    },
    {
      code: 'GH',
      label: 'Ghana',
      phone: '233',
      labelArabic: 'غانا',
      phone_arabic: '233',
      value: 'Ghana'
    },
    {
      code: 'GI',
      label: 'Gibraltar',
      phone: '350',
      labelArabic: 'جبل طارق',
      phone_arabic: '350',
      value: 'Gibraltar'
    },
    {
      code: 'GL',
      label: 'Greenland',
      phone: '299',
      labelArabic: 'جرينلاند',
      phone_arabic: '299',
      value: 'Greenland'
    },
    {
      code: 'GM',
      label: 'Gambia',
      phone: '220',
      labelArabic: 'غامبيا',
      phone_arabic: '220',
      value: 'Gambia'
    },
    {
      code: 'GN',
      label: 'Guinea',
      phone: '224',
      labelArabic: 'غينيا',
      phone_arabic: '224',
      value: 'Guinea'
    },
    {
      code: 'GP',
      label: 'Guadeloupe',
      phone: '590',
      labelArabic: 'غوادلوب',
      phone_arabic: '590',
      value: 'Guadeloupe'
    },
    {
      code: 'GQ',
      label: 'Equatorial Guinea',
      phone: '240',
      labelArabic: 'غينيا الاستوائية',
      phone_arabic: '240',
      value: 'Equatorial Guinea'
    },
    {
      code: 'GR',
      label: 'Greece',
      phone: '30',
      labelArabic: 'اليونان',
      phone_arabic: '30',
      value: 'Greece'
    },
    {
      code: 'GS',
      label: 'South Georgia and the South Sandwich Islands',
      phone: '500',
      labelArabic: 'جورجيا الجنوبية وجزر ساندويتش الجنوبية',
      phone_arabic: '500',
      value: 'South Georgia and the South Sandwich Islands'
    },
    {
      code: 'GT',
      label: 'Guatemala',
      phone: '502',
      labelArabic: 'غواتيمالا',
      phone_arabic: '502',
      value: 'Guatemala'
    },
    {
      code: 'GU',
      label: 'Guam',
      phone: '1-671',
      labelArabic: 'غوام',
      phone_arabic: '1-671',
      value: 'Guam'
    },
    {
      code: 'GW',
      label: 'Guinea-Bissau',
      phone: '245',
      labelArabic: 'غينيا بيساو',
      phone_arabic: '245',
      value: 'Guinea-Bissau'
    },
    {
      code: 'GY',
      label: 'Guyana',
      phone: '592',
      labelArabic: 'غيانا',
      phone_arabic: '592',
      value: 'Guyana'
    },
    {
      code: 'HK',
      label: 'Hong Kong',
      phone: '852',
      labelArabic: 'هونغ كونغ',
      phone_arabic: '852',
      value: 'Hong Kong'
    },
    {
      code: 'HM',
      label: 'Heard Island and McDonald Islands',
      phone: '672',
      labelArabic: 'جزيرة هيرد وجزر ماكدونالد',
      phone_arabic: '672',
      value: 'Heard Island and McDonald Islands'
    },
    {
      code: 'HN',
      label: 'Honduras',
      phone: '504',
      labelArabic: 'هندوراس',
      phone_arabic: '504',
      value: 'Honduras'
    },
    {
      code: 'HR',
      label: 'Croatia',
      phone: '385',
      labelArabic: 'كرواتيا',
      phone_arabic: '385',
      value: 'Croatia'
    },
    {
      code: 'HT',
      label: 'Haiti',
      phone: '509',
      labelArabic: 'هايتي',
      phone_arabic: '509',
      value: 'Haiti'
    },
    {
      code: 'HU',
      label: 'Hungary',
      phone: '36',
      labelArabic: 'هنغاريا',
      phone_arabic: '36',
      value: 'Hungary'
    },
    {
      code: 'ID',
      label: 'Indonesia',
      phone: '62',
      labelArabic: 'إندونيسيا',
      phone_arabic: '62',
      value: 'Indonesia'
    },
    {
      code: 'IE',
      label: 'Ireland',
      phone: '353',
      labelArabic: 'أيرلندا',
      phone_arabic: '353',
      value: 'Ireland'
    },
    {
      code: 'IL',
      label: 'Israel',
      phone: '972',
      labelArabic: 'إسرائيل',
      phone_arabic: '972',
      value: 'Israel'
    },
    {
      code: 'IM',
      label: 'Isle of Man',
      phone: '44',
      labelArabic: 'جزيرة مان',
      phone_arabic: '44',
      value: 'Isle of Man'
    },


 {
    code: 'IN',
    label: 'India',
    phone: '91',
    labelArabic: 'الهند',
    phone_arabic: '91',
    value: 'India'
  },
  {
    code: 'IO',
    label: 'British Indian Ocean Territory',
    phone: '246',
    labelArabic: 'إقليم المحيط الهندي البريطاني',
    phone_arabic: '246',
    value: 'British Indian Ocean Territory'
  },
  {
    code: 'IQ',
    label: 'Iraq',
    phone: '964',
    labelArabic: 'العراق',
    phone_arabic: '964',
    value: 'Iraq'
  },
  {
    code: 'IR',
    label: 'Iran',
    phone: '98',
    labelArabic: 'إيران',
    phone_arabic: '98',
    value: 'Iran'
  },
  {
    code: 'IS',
    label: 'Iceland',
    phone: '354',
    labelArabic: 'آيسلندا',
    phone_arabic: '354',
    value: 'Iceland'
  },
  {
    code: 'IT',
    label: 'Italy',
    phone: '39',
    labelArabic: 'إيطاليا',
    phone_arabic: '39',
    value: 'Italy'
  },
  {
    code: 'JE',
    label: 'Jersey',
    phone: '44',
    labelArabic: 'جيرسي',
    phone_arabic: '44',
    value: 'Jersey'
  },
  {
    code: 'JM',
    label: 'Jamaica',
    phone: '1-876',
    labelArabic: 'جامايكا',
    phone_arabic: '1-876',
    value: 'Jamaica'
  },
  {
    code: 'JP',
    label: 'Japan',
    phone: '81',
    suggested: true,
    labelArabic: 'اليابان',
    phone_arabic: '81',
    value: 'Japan'
  },
  {
    code: 'KE',
    label: 'Kenya',
    phone: '254',
    labelArabic: 'كينيا',
    phone_arabic: '254',
    value: 'Kenya'
  },
  {
    code: 'KG',
    label: 'Kyrgyzstan',
    phone: '996',
    labelArabic: 'قيرغيزستان',
    phone_arabic: '996',
    value: 'Kyrgyzstan'
  },
  {
    code: 'KH',
    label: 'Cambodia',
    phone: '855',
    labelArabic: 'كمبوديا',
    phone_arabic: '855',
    value: 'Cambodia'
  },
  {
    code: 'KI',
    label: 'Kiribati',
    phone: '686',
    labelArabic: 'كيريباتي',
    phone_arabic: '686',
    value: 'Kiribati'
  },
  {
    code: 'KM',
    label: 'Comoros',
    phone: '269',
    labelArabic: 'جزر القمر',
    phone_arabic: '269',
    value: 'Comoros'
  },
  {
    code: 'KN',
    label: 'Saint Kitts and Nevis',
    phone: '1-869',
    labelArabic: 'سانت كيتس ونيفيس',
    phone_arabic: '1-869',
    value: 'Saint Kitts and Nevis'
  },
  {
    code: 'KP',
    label: "Korea, Democratic People's Republic of",
    phone: '850',
    labelArabic: 'كوريا، الجمهورية الشعبية الديمقراطية',
    phone_arabic: '850',
    value: "Korea, Democratic People's Republic of"
  },
  {
    code: 'KR',
    label: 'Korea, Republic of',
    phone: '82',
    labelArabic: 'كوريا، جمهورية',
    phone_arabic: '82',
    value: 'Korea, Republic of'
  },
  {
    code: 'KW',
    label: 'Kuwait',
    phone: '965',
    labelArabic: 'الكويت',
    phone_arabic: '965',
    value: 'Kuwait'
  },
  {
    code: 'KY',
    label: 'Cayman Islands',
    phone: '1-345',
    labelArabic: 'جزر كايمان',
    phone_arabic: '1-345',
    value: 'Cayman Islands'
  },
  {
    code: 'KZ',
    label: 'Kazakhstan',
    phone: '7',
    labelArabic: 'كازاخستان',
    phone_arabic: '7',
    value: 'Kazakhstan'
  },
  {
    code: 'LA',
    label: "Lao People's Democratic Republic",
    phone: '856',
    labelArabic: 'جمهورية لاو الديمقراطية الشعبية',
    phone_arabic: '856',
    value: "Lao People's Democratic Republic"
  },
  {
    code: 'LB',
    label: 'Lebanon',
    phone: '961',
    labelArabic: 'لبنان',
    phone_arabic: '961',
    value: 'Lebanon'
  },
  {
    code: 'LC',
    label: 'Saint Lucia',
    phone: '1-758',
    labelArabic: 'سانت لوسيا',
    phone_arabic: '1-758',
    value: 'Saint Lucia'
  },
  {
    code: 'LI',
    label: 'Liechtenstein',
    phone: '423',
    labelArabic: 'ليختنشتاين',
    phone_arabic: '423',
    value: 'Liechtenstein'
  },
  {
    code: 'LK',
    label: 'Sri Lanka',
    phone: '94',
    labelArabic: 'سريلانكا',
    phone_arabic: '94',
    value: 'Sri Lanka'
  },
  {
    code: 'LR',
    label: 'Liberia',
    phone: '231',
    labelArabic: 'ليبيريا',
    phone_arabic: '231',
    value: 'Liberia'
  },
  {
    code: 'LS',
    label: 'Lesotho',
    phone: '266',
    labelArabic: 'ليسوتو',
    phone_arabic: '266',
    value: 'Lesotho'
  },
  {
    code: 'LT',
    label: 'Lithuania',
    phone: '370',
    labelArabic: 'ليتوانيا',
    phone_arabic: '370',
    value: 'Lithuania'
  },
  {
    code: 'LU',
    label: 'Luxembourg',
    phone: '352',
    labelArabic: 'لوكسمبورغ',
    phone_arabic: '352',
    value: 'Luxembourg'
  },
  {
    code: 'LV',
    label: 'Latvia',
    phone: '371',
    labelArabic: 'لاتفيا',
    phone_arabic: '371',
    value: 'Latvia'
  },
  {
    code: 'LY',
    label: 'Libya',
    phone: '218',
    labelArabic: 'ليبيا',
    phone_arabic: '218',
    value: 'Libya'
  },
  {
    code: 'MA',
    label: 'Morocco',
    phone: '212',
    labelArabic: 'المغرب',
    phone_arabic: '212',
    value: 'Morocco'
  },
  {
    code: 'MC',
    label: 'Monaco',
    phone: '377',
    labelArabic: 'موناكو',
    phone_arabic: '377',
    value: 'Monaco'
  },
  {
    code: 'MD',
    label: 'Moldova, Republic of',
    phone: '373',
    labelArabic: 'مولدافيا، جمهورية',
    phone_arabic: '373',
    value: 'Moldova, Republic of'
  },
  {
    code: 'ME',
    label: 'Montenegro',
    phone: '382',
    labelArabic: 'الجبل الأسود',
    phone_arabic: '382',
    value: 'Montenegro'
  },
  {
    code: 'MF',
    label: 'Saint Martin (French part)',
    phone: '590',
    labelArabic: 'سانت مارتن (الجزء الفرنسي)',
    phone_arabic: '590',
    value: 'Saint Martin (French part)'
  },
  {
    code: 'MG',
    label: 'Madagascar',
    phone: '261',
    labelArabic: 'مدغشقر',
    phone_arabic: '261',
    value: 'Madagascar'
  },
  {
    code: 'MH',
    label: 'Marshall Islands',
    phone: '692',
    labelArabic: 'جزر مارشال',
    phone_arabic: '692',
    value: 'Marshall Islands'
  },
  {
    code: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
    phone: '389',
    labelArabic: 'مقدونيا، جمهورية يوغوسلافيا السابقة',
    phone_arabic: '389',
    value: 'Macedonia, the Former Yugoslav Republic of'
  },
  {
    code: 'ML',
    label: 'Mali',
    phone: '223',
    labelArabic: 'مالي',
    phone_arabic: '223',
    value: 'Mali'
  },
  {
    code: 'MM',
    label: 'Myanmar',
    phone: '95',
    labelArabic: 'ميانمار',
    phone_arabic: '95',
    value: 'Myanmar'
  },
  {
    code: 'MN',
    label: 'Mongolia',
    phone: '976',
    labelArabic: 'منغوليا',
    phone_arabic: '976',
    value: 'Mongolia'
  },
  {
    code: 'MO',
    label: 'Macao',
    phone: '853',
    labelArabic: 'ماكاو',
    phone_arabic: '853',
    value: 'Macao'
  },
  {
    code: 'MP',
    label: 'Northern Mariana Islands',
    phone: '1-670',
    labelArabic: 'جزر ماريانا الشمالية',
    phone_arabic: '1-670',
    value: 'Northern Mariana Islands'
  },
  {
    code: 'MQ',
    label: 'Martinique',
    phone: '596',
    labelArabic: 'مارتينيك',
    phone_arabic: '596',
    value: 'Martinique'
  },
  {
    code: 'MR',
    label: 'Mauritania',
    phone: '222',
    labelArabic: 'موريتانيا',
    phone_arabic: '222',
    value: 'Mauritania'
  },
  {
    code: 'MS',
    label: 'Montserrat',
    phone: '1-664',
    labelArabic: 'مونتسيرات',
    phone_arabic: '1-664',
    value: 'Montserrat'
  },
  {
    code: 'MT',
    label: 'Malta',
    phone: '356',
    labelArabic: 'مالطا',
    phone_arabic: '356',
    value: 'Malta'
  },
  {
    code: 'MU',
    label: 'Mauritius',
    phone: '230',
    labelArabic: 'موريشيوس',
    phone_arabic: '230',
    value: 'Mauritius'
  },
  {
    code: 'MV',
    label: 'Maldives',
    phone: '960',
    labelArabic: 'المالديف',
    phone_arabic: '960',
    value: 'Maldives'
  },
  {
    code: 'MW',
    label: 'Malawi',
    phone: '265',
    labelArabic: 'مالاوي',
    phone_arabic: '265',
    value: 'Malawi'
  },
  {
    code: 'MX',
    label: 'Mexico',
    phone: '52',
    labelArabic: 'المكسيك',
    phone_arabic: '52',
    value: 'Mexico'
  },
  {
    code: 'MY',
    label: 'Malaysia',
    phone: '60',
    labelArabic: 'ماليزيا',
    phone_arabic: '60',
    value: 'Malaysia'
  },
  {
    code: 'MZ',
    label: 'Mozambique',
    phone: '258',
    labelArabic: 'موزمبيق',
    phone_arabic: '258',
    value: 'Mozambique'
  },
  {
    code: 'NA',
    label: 'Namibia',
    phone: '264',
    labelArabic: 'ناميبيا',
    phone_arabic: '264',
    value: 'Namibia'
  },
  {
    code: 'NC',
    label: 'New Caledonia',
    phone: '687',
    labelArabic: 'كاليدونيا الجديدة',
    phone_arabic: '687',
    value: 'New Caledonia'
  },
  {
    code: 'NE',
    label: 'Niger',
    phone: '227',
    labelArabic: 'النيجر',
    phone_arabic: '227',
    value: 'Niger'
  },
  {
    code: 'NF',
    label: 'Norfolk Island',
    phone: '672',
    labelArabic: 'جزيرة نورفولك',
    phone_arabic: '672',
    value: 'Norfolk Island'
  },
  {
    code: 'NG',
    label: 'Nigeria',
    phone: '234',
    labelArabic: 'نيجيريا',
    phone_arabic: '234',
    value: 'Nigeria'
  },
  {
    code: 'NI',
    label: 'Nicaragua',
    phone: '505',
    labelArabic: 'نيكاراغوا',
    phone_arabic: '505',
    value: 'Nicaragua'
  },
  {
    code: 'NL',
    label: 'Netherlands',
    phone: '31',
    labelArabic: 'هولندا',
    phone_arabic: '31',
    value: 'Netherlands'
  },
  {
    code: 'NO',
    label: 'Norway',
    phone: '47',
    labelArabic: 'النرويج',
    phone_arabic: '47',
    value: 'Norway'
  },
  {
    code: 'NP',
    label: 'Nepal',
    phone: '977',
    labelArabic: 'نيبال',
    phone_arabic: '977',
    value: 'Nepal'
  },
  {
    code: 'NR',
    label: 'Nauru',
    phone: '674',
    labelArabic: 'ناورو',
    phone_arabic: '674',
    value: 'Nauru'
  },
  {
    code: 'NU',
    label: 'Niue',
    phone: '683',
    labelArabic: 'نيوي',
    phone_arabic: '683',
    value: 'Niue'
  },
  {
    code: 'NZ',
    label: 'New Zealand',
    phone: '64',
    labelArabic: 'نيوزيلندا',
    phone_arabic: '64',
    value: 'New Zealand'
  },
  {
    code: 'PA',
    label: 'Panama',
    phone: '507',
    labelArabic: 'بنما',
    phone_arabic: '507',
    value: 'Panama'
  },
  {
    code: 'PE',
    label: 'Peru',
    phone: '51',
    labelArabic: 'بيرو',
    phone_arabic: '51',
    value: 'Peru'
  },
  {
    code: 'PF',
    label: 'French Polynesia',
    phone: '689',
    labelArabic: 'بولينيزيا الفرنسية',
    phone_arabic: '689',
    value: 'French Polynesia'
  },
  {
    code: 'PG',
    label: 'Papua New Guinea',
    phone: '675',
    labelArabic: 'بابوا غينيا الجديدة',
    phone_arabic: '675',
    value: 'Papua New Guinea'
  },
  {
    code: 'PH',
    label: 'Philippines',
    phone: '63',
    labelArabic: 'الفيلبين',
    phone_arabic: '63',
    value: 'Philippines'
  },
  {
    code: 'PK',
    label: 'Pakistan',
    phone: '92',
    labelArabic: 'باكستان',
    phone_arabic: '92',
    value: 'Pakistan'
  },
  {
    code: 'PL',
    label: 'Poland',
    phone: '48',
    labelArabic: 'بولندا',
    phone_arabic: '48',
    value: 'Poland'
  },
  {
    code: 'PM',
    label: 'Saint Pierre and Miquelon',
    phone: '508',
    labelArabic: 'سان بيير وميكلون',
    phone_arabic: '508',
    value: 'Saint Pierre and Miquelon'
  },
  {
    code: 'PN',
    label: 'Pitcairn',
    phone: '870',
    labelArabic: 'بيتكيرن',
    phone_arabic: '870',
    value: 'Pitcairn'
  },
  {
    code: 'PR',
    label: 'Puerto Rico',
    phone: '1',
    labelArabic: 'بورتوريكو',
    phone_arabic: '1',
    value: 'Puerto Rico'
  },
  {
    code: 'PS',
    label: 'Palestine, State of',
    phone: '970',
    labelArabic: 'فلسطين',
    phone_arabic: '970',
    value: 'Palestine, State of'
  },
  {
    code: 'PT',
    label: 'Portugal',
    phone: '351',
    labelArabic: 'البرتغال',
    phone_arabic: '351',
    value: 'Portugal'
  },
  {
    code: 'PW',
    label: 'Palau',
    phone: '680',
    labelArabic: 'بالاو',
    phone_arabic: '680',
    value: 'Palau'
  },
  {
    code: 'PY',
    label: 'Paraguay',
    phone: '595',
    labelArabic: 'باراغواي',
    phone_arabic: '595',
    value: 'Paraguay'
  },
  {
    code: 'RE',
    label: 'Reunion',
    phone: '262',
    labelArabic: 'ريونيون',
    phone_arabic: '262',
    value: 'Reunion'
  },
  {
    code: 'RO',
    label: 'Romania',
    phone: '40',
    labelArabic: 'رومانيا',
    phone_arabic: '40',
    value: 'Romania'
  },
  {
    code: 'RS',
    label: 'Serbia',
    phone: '381',
    labelArabic: 'صربيا',
    phone_arabic: '381',
    value: 'Serbia'
  },
  {
    code: 'RU',
    label: 'Russian Federation',
    phone: '7',
    labelArabic: 'الاتحاد الروسي',
    phone_arabic: '7',
    value: 'Russian Federation'
  },
  {
    code: 'RW',
    label: 'Rwanda',
    phone: '250',
    labelArabic: 'رواندا',
    phone_arabic: '250',
    value: 'Rwanda'
  },
  {
    code: 'SB',
    label: 'Solomon Islands',
    phone: '677',
    labelArabic: 'جزر سليمان',
    phone_arabic: '677',
    value: 'Solomon Islands'
  },
  {
    code: 'SC',
    label: 'Seychelles',
    phone: '248',
    labelArabic: 'سيشل',
    phone_arabic: '248',
    value: 'Seychelles'
  },
  {
    code: 'SD',
    label: 'Sudan',
    phone: '249',
    labelArabic: 'السودان',
    phone_arabic: '249',
    value: 'Sudan'
  },
  {
    code: 'SE',
    label: 'Sweden',
    phone: '46',
    labelArabic: 'السويد',
    valueArabic: 'السويد',
    value: 'Sweden'
  },
  {
    code: 'SG',
    label: 'Singapore',
    phone: '65',
    labelArabic: 'سنغافورة',
    valueArabic: 'سنغافورة',
    value: 'Singapore'
  },
  {
    code: 'SH',
    label: 'Saint Helena',
    phone: '290',
    labelArabic: 'سانت هيلينا',
    valueArabic: 'سانت هيلينا',
    value: 'Saint Helena'
  },
  {
    code: 'SI',
    label: 'Slovenia',
    phone: '386',
    labelArabic: 'سلوفينيا',
    valueArabic: 'سلوفينيا',
    value: 'Slovenia'
  },
  {
    code: 'SJ',
    label: 'Svalbard and Jan Mayen',
    phone: '47',
    labelArabic: 'سفالبارد ويان ماين',
    valueArabic: 'سفالبارد ويان ماين',
    value: 'Svalbard and Jan Mayen'
  },
  {
    code: 'SK',
    label: 'Slovakia',
    phone: '421',
    labelArabic: 'سلوفاكيا',
    valueArabic: 'سلوفاكيا',
    value: 'Slovakia'
  },
  {
    code: 'SL',
    label: 'Sierra Leone',
    phone: '232',
    labelArabic: 'سيراليون',
    valueArabic: 'سيراليون',
    value: 'Sierra Leone'
  },
  {
    code: 'SM',
    label: 'San Marino',
    phone: '378',
    labelArabic: 'سان مارينو',
    valueArabic: 'سان مارينو',
    value: 'San Marino'
  },
  {
    code: 'SN',
    label: 'Senegal',
    phone: '221',
    labelArabic: 'السنغال',
    valueArabic: 'السنغال',
    value: 'Senegal'
  },
  {
    code: 'SO',
    label: 'Somalia',
    phone: '252',
    labelArabic: 'الصومال',
    phone_arabic: '252',
    value: 'Somalia',
    valueArabic: 'الصومال'
  },
  {
    code: 'SR',
    label: 'Suriname',
    phone: '597',
    labelArabic: 'سورينام',
    phone_arabic: '597',
    value: 'Suriname',
    valueArabic: 'سورينام'
  },
  {
    code: 'SS',
    label: 'South Sudan',
    phone: '211',
    labelArabic: 'جنوب السودان',
    phone_arabic: '211',
    value: 'South Sudan',
    valueArabic: 'جنوب السودان'
  },
  {
    code: 'ST',
    label: 'Sao Tome and Principe',
    phone: '239',
    labelArabic: 'ساو تومي وبرينسيبي',
    phone_arabic: '239',
    value: 'Sao Tome and Principe',
    valueArabic: 'ساو تومي وبرينسيبي'
  },
  {
    code: 'SV',
    label: 'El Salvador',
    phone: '503',
    labelArabic: 'السلفادور',
    phone_arabic: '503',
    value: 'El Salvador',
    valueArabic: 'السلفادور'
  },  
  {
    code: 'SX',
    label: 'Sint Maarten (Dutch part)',
    phone: '1-721',
    labelArabic: 'سانت مارتن (الجزء الهولندي)',
    phone_arabic: '1-721',
    value: 'Sint Maarten (Dutch part)',
    valueArabic: 'سانت مارتن (الجزء الهولندي)'
  },
  {
    code: 'SY',
    label: 'Syrian Arab Republic',
    phone: '963',
    labelArabic: 'الجمهورية العربية السورية',
    phone_arabic: '963',
    value: 'Syrian Arab Republic',
    valueArabic: 'الجمهورية العربية السورية'
  },
  {
    code: 'SZ',
    label: 'Swaziland',
    phone: '268',
    labelArabic: 'سوازيلاند',
    phone_arabic: '268',
    value: 'Swaziland',
    valueArabic: 'سوازيلاند'
  },
  {
    code: 'TC',
    label: 'Turks and Caicos Islands',
    phone: '1-649',
    labelArabic: 'جزر توركس وكايكوس',
    phone_arabic: '1-649',
    value: 'Turks and Caicos Islands',
    valueArabic: 'جزر توركس وكايكوس'
  },
  {
    code: 'TD',
    label: 'Chad',
    phone: '235',
    labelArabic: 'تشاد',
    phone_arabic: '235',
    value: 'Chad',
    valueArabic: 'تشاد'
  },
  {
    code: 'TF',
    label: 'French Southern Territories',
    phone: '262',
    labelArabic: 'المقاطعات الجنوبية الفرنسية',
    phone_arabic: '262',
    value: 'French Southern Territories',
    valueArabic: 'المقاطعات الجنوبية الفرنسية'
  },
  {
    code: 'TG',
    label: 'Togo',
    phone: '228',
    labelArabic: 'توغو',
    phone_arabic: '228',
    value: 'Togo',
    valueArabic: 'توغو'
  },
  {
    code: 'TH',
    label: 'Thailand',
    phone: '66',
    labelArabic: 'تايلاند',
    phone_arabic: '66',
    value: 'Thailand',
    valueArabic: 'تايلاند'
  },
  {
    code: 'TJ',
    label: 'Tajikistan',
    phone: '992',
    labelArabic: 'طاجيكستان',
    phone_arabic: '992',
    value: 'Tajikistan',
    valueArabic: 'طاجيكستان'
  },
  {
    code: 'TK',
    label: 'Tokelau',
    phone: '690',
    labelArabic: 'توكيلاو',
    phone_arabic: '690',
    value: 'Tokelau',
    valueArabic: 'توكيلاو'
  },
  {
    code: 'TL',
    label: 'Timor-Leste',
    phone: '670',
    labelArabic: 'تيمور الشرقية',
    phone_arabic: '670',
    value: 'Timor-Leste',
    valueArabic: 'تيمور الشرقية'
  },
  {
    code: 'TM',
    label: 'Turkmenistan',
    phone: '993',
    labelArabic: 'تركمانستان',
    phone_arabic: '993',
    value: 'Turkmenistan',
    valueArabic: 'تركمانستان'
  },
  {
    code: 'TN',
    label: 'Tunisia',
    phone: '216',
    labelArabic: 'تونس',
    phone_arabic: '216',
    value: 'Tunisia',
    valueArabic: 'تونس'
  },
  {
    code: 'TO',
    label: 'Tonga',
    phone: '676',
    labelArabic: 'تونغا',
    phone_arabic: '676',
    value: 'Tonga',
    valueArabic: 'تونغا'
  },
  {
    code: 'TR',
    label: 'Turkey',
    phone: '90',
    labelArabic: 'تركيا',
    phone_arabic: '90',
    value: 'Turkey',
    valueArabic: 'تركيا'
  },
  {
    code: 'TT',
    label: 'Trinidad and Tobago',
    phone: '1-868',
    labelArabic: 'ترينيداد وتوباغو',
    phone_arabic: '1-868',
    value: 'Trinidad and Tobago',
    valueArabic: 'ترينيداد وتوباغو'
  },
  {
    code: 'TV',
    label: 'Tuvalu',
    phone: '688',
    labelArabic: 'توفالو',
    phone_arabic: '688',
    value: 'Tuvalu',
    valueArabic: 'توفالو'
  },
  {
    code: 'TW',
    label: 'Taiwan',
    phone: '886',
    labelArabic: 'تايوان',
    phone_arabic: '886',
    value: 'Taiwan',
    valueArabic: 'تايوان'
  },
  {
    code: 'TZ',
    label: 'United Republic of Tanzania',
    phone: '255',
    labelArabic: 'جمهورية تنزانيا المتحدة',
    phone_arabic: '255',
    value: 'United Republic of Tanzania',
    valueArabic: 'جمهورية تنزانيا المتحدة'
  },
  {
    code: 'UA',
    label: 'Ukraine',
    phone: '380',
    labelArabic: 'أوكرانيا',
    phone_arabic: '380',
    value: 'Ukraine',
    valueArabic: 'أوكرانيا'
  },
  {
    code: 'UG',
    label: 'Uganda',
    phone: '256',
    labelArabic: 'أوغندا',
    phone_arabic: '256',
    value: 'Uganda',
    valueArabic: 'أوغندا'
  },
  {
    code: 'US',
    label: 'United States',
    phone: '1',
    suggested: true,
    labelArabic: 'الولايات المتحدة الأمريكية',
    valueArabic: 'الولايات المتحدة الأمريكية',
    phone_arabic: '1',
    value: 'United States'
  },
  {
    code: 'UY',
    label: 'Uruguay',
    phone: '598',
    labelArabic: 'أوروغواي',
    valueArabic: 'أوروغواي',
    phone_arabic: '598',
    value: 'Uruguay'
  },
  {
    code: 'UZ',
    label: 'Uzbekistan',
    phone: '998',
    labelArabic: 'أوزبكستان',
    valueArabic: 'أوزبكستان',
    phone_arabic: '998',
    value: 'Uzbekistan'
  },
  {
    code: 'VA',
    label: 'Holy See (Vatican City State)',
    phone: '379',
    labelArabic: 'الكرسي الرسولي (الفاتيكان)',
    valueArabic: 'الكرسي الرسولي (الفاتيكان)',
    phone_arabic: '379',
    value: 'Holy See (Vatican City State)'
  },
  {
    code: 'VC',
    label: 'Saint Vincent and the Grenadines',
    phone: '1-784',
    labelArabic: 'سانت فنسنت والغرينادين',
    valueArabic: 'سانت فنسنت والغرينادين',
    value: 'Saint Vincent and the Grenadines'
  },
  {
    code: 'VE',
    label: 'Venezuela',
    phone: '58',
    labelArabic: 'فنزويلا',
    valueArabic: 'فنزويلا',
    value: 'Venezuela'
  },
  {
    code: 'VG',
    label: 'British Virgin Islands',
    phone: '1-284',
    labelArabic: 'جزر العذراء البريطانية',
    valueArabic: 'جزر العذراء البريطانية',
    value: 'British Virgin Islands'
  },
  {
    code: 'VI',
    label: 'US Virgin Islands',
    phone: '1-340',
    labelArabic: 'جزر العذراء الأمريكية',
    valueArabic: 'جزر العذراء الأمريكية',
    value: 'US Virgin Islands'
  },
  {
    code: 'VN',
    label: 'Vietnam',
    phone: '84',
    labelArabic: 'فيتنام',
    valueArabic: 'فيتنام',
    value: 'Vietnam'
  },
  {
    code: 'VU',
    label: 'Vanuatu',
    phone: '678',
    labelArabic: 'فانواتو',
    valueArabic: 'فانواتو',
    value: 'Vanuatu'
  },
  {
    code: 'WF',
    label: 'Wallis and Futuna',
    phone: '681',
    labelArabic: 'واليس وفوتونا',
    valueArabic: 'واليس وفوتونا',
    value: 'Wallis and Futuna'
  },
  {
    code: 'WS',
    label: 'Samoa',
    phone: '685',
    labelArabic: 'ساموا',
    valueArabic: 'ساموا',
    value: 'Samoa'
  },
  {
    code: 'XK',
    label: 'Kosovo',
    phone: '383',
    labelArabic: 'كوسوفو',
    valueArabic: 'كوسوفو',
    value: 'Kosovo'
  },
  {
    code: 'YE',
    label: 'Yemen',
    phone: '967',
    labelArabic: 'اليمن',
    valueArabic: 'اليمن',
    value: 'Yemen'
  },
  {
    code: 'YT',
    label: 'Mayotte',
    phone: '262',
    labelArabic: 'مايوت',
    valueArabic: 'مايوت',
    value: 'Mayotte'
  },
  {
    code: 'ZA',
    label: 'South Africa',
    phone: '27',
    labelArabic: 'جنوب أفريقيا',
    valueArabic: 'جنوب أفريقيا',
    value: 'South Africa'
  },
  {
    code: 'ZM',
    label: 'Zambia',
    phone: '260',
    labelArabic: 'زامبيا',
    valueArabic: 'زامبيا',
    value: 'Zambia'
  },
  {
    code: 'ZW',
    label: 'Zimbabwe',
    phone: '263',
    labelArabic: 'زيمبابوي',
    valueArabic: 'زيمبابوي',
    value: 'Zimbabwe'
  }
  ]);



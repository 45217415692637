import React, { useState } from "react";

import { EyeIcon, EyeOff } from "../../../assets/SVG/svg";
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTracking } from "react-tracking";
import { useTranslation } from "react-i18next";

const InputPassword = ({
  hasForgotpassword,
  forgotPassword,
  value,
  styleclass,
  onChange,
  placeholder,
  error,
  label,
  autoComplete
}) => {
  const {trackEvent} = useTracking()
  const [type, setType] = useState("password");
  const {t} = useTranslation()

  const eyeBtnStyle = {
    position: "absolute",
    left: "0px",
    right: ""
  }
  const isArabic = localStorage.getItem('i18nextLng')?.toString() === 'ar';

  const eye_btn_ar = {
    left: '2px',
    borderTopLeftRadius: '60px',
    borderBottomLeftRadius: '60px',
    height:"49px"
  }

  const eye_btn_en = {
    right: '2px',
    borderTopRightRadius: '60px',
    borderBottomRightRadius: '60px',
    height:"49px"
  }

  return (
    <div className="mb-3 password-input" style={{direction: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "rtl" : "ltr"}`}}>
      <div className="d-flex justify-content-between">
        <label htmlFor="basic-url" className="form-label mt-1">
          {label ?? `${t("password")}`}
        </label>
        <p className="mb-2 ff-pasword">
          {hasForgotpassword && (
            <Link to={"/forgotpassword"} variant="p" style={{fontFamily:'karla',}}>
              {t("forgot_password")}
            </Link>
          )}
        </p>
      </div>
        <div className={`input-group ${styleclass}`}>
          <div className={`passwordInputWrapper`}         
              style={{direction: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "rtl" : "ltr"}`}}>

            <form onSubmit={(e)=> e.preventDefault()} autocomplete={autoComplete} >
            <input
              onKeyDown={(e)=>{
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevent default behavior for Enter key
                }
              }}
              type={type}
              className={`form-control  ${styleclass}  ${
                error ? "is-invalid" : ""
              }`}
              placeholder={placeholder ? placeholder : `${t("enter_your")} ${t("password")}`}
              aria-label="password input label"
              value={value}
              onChange={onChange}
              autoComplete={autoComplete}
              />    
               
            <button
              className="eye_btn"
              style={ localStorage.getItem('i18nextLng')?.toString() === 'ar' ? eye_btn_ar : eye_btn_en}
              type="button"
              onClick={() => {
                if (type === "password") {
                  setType("text");
                  trackEvent({event_type: "click", event_name: "User set Password to 'Unhidden' in Sign up", event_data: {link: "/signup"} })
                } else {
                  setType("password");
                  trackEvent({event_type: "click", event_name: "User set Password to 'Hidden' in Sign up", event_data: {link: "/signup"} })
                }
              }}
            >
              {type === "text" ? <EyeIcon /> : <EyeOff />}
            </button> 
            
            {error && <div className="invalid-feedback">{error}</div>}
                      
            </form>


          </div>
        </div>
    </div>
  );
};

export default InputPassword;

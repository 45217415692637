import { createSlice } from "@reduxjs/toolkit";


const requestKillerSlice = createSlice({
    name:'killRequest',
    initialState: "none",
    reducers: {
        setKillRequest: (state, action) => {
            return action.payload
        }
    }
})

export const {setKillRequest} = requestKillerSlice.actions;
export default requestKillerSlice.reducer
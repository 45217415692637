import { create } from "@mui/material/styles/createTransitions";
import { createSlice } from "@reduxjs/toolkit";


const questionerStatusSlice = createSlice({
    name:'questioner_filled',
    initialState: false,
    reducers: {
        setQuestionerFilled: (state, action) => {
            return action.payload;
        }
    }
})

export const {setQuestionerFilled} = questionerStatusSlice.actions;
export default questionerStatusSlice.reducer
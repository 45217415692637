import { create } from "@mui/material/styles/createTransitions";
import { createSlice } from "@reduxjs/toolkit";

// const initialState: CounterState = {value:0} // annotations are only for TypeScript
const initialValue = {
    profileId: "",
    profileStatus: false,
    modalOpen: false,
}

const profileCompletionSlice = createSlice({
    name:'profileCompletion',
    initialState: initialValue,
    reducers: {
        setProfileCompletion: (state, action) => {
            state.profileStatus = action.payload
        },
        setProfileId: (state, action) => {
            state.profileId = action.payload
        },
        setProfileCompleteModalState: (state, action) => {
            state.modalOpen = action.payload
        }
    }
})

export const {setProfileCompletion, setProfileId, setProfileCompleteModalState} = profileCompletionSlice.actions;
export default profileCompletionSlice.reducer
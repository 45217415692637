import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

// Import translation files
import enTranslation from './locales/en/translation.json';
import arTranslation from './locales/ar/translation.json';

const i18n = i18next.createInstance();

const resources = {
    en: {
      translation: enTranslation
    },
    ar: {
      translation: arTranslation
    }
  };

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    // .use(HttpApi)
    .init({
        resources,
        initImmediate: true, // Delay initialization until the first translation is needed.
        detection: {
            order: ['localStorage', 'navigator', 'htmlTag', 'queryString'],
            whitelist: ['en', 'ar'],
          },
        debug: false, // for use in dev environment so errors get logged to console
        // lng: 'ar', // for hardcoding language,
        fallbackLng: 'en',
        whitelist: ['en', 'ar'],

        // uses HTTPBackend, fetches locale files from public folder
        // backend: {
        //     loadPath: '/locales/{{lng}}/translation.json'
        // },

        react: {useSuspense: false}


    })

    export default i18n
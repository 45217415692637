import Spinner from 'react-bootstrap/Spinner';

function ButtonSpinnerCustom(props) {
  return (
    <>
        <Spinner
          style={props?.style}
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
        />
    
    </>
  );
}

export default ButtonSpinnerCustom;
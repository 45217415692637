import { createSlice } from "@reduxjs/toolkit";

// const initialState: CounterState = {value:0} // annotations are only for TypeScript
const displayState = {
    value: 'none',
}

const sidebarSlice = createSlice({
    name:'displaySidebar',
    initialState: displayState,
    reducers: {
        setDisplaySidebar: (state, action) => {
            state.value = action.payload
        }
    }
})

export const {setDisplaySidebar} = sidebarSlice.actions;
export default sidebarSlice.reducer
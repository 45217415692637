import React, { useState, useEffect } from "react";
import "./personalinfo.scss";
import Profileimg from "../../assets/images/banner.png";
import InputField from "../common/InputField/InputField";
import { EditpencilIcon } from "../../assets/SVG/svg";
import testImg from "../../assets/images/card.jpg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setProfileImage } from "../../ReduxSlices/ProfileImageSlice";
// import * as _ from 'lodash'
import axios from "axios";
import { SERVICES } from "../../utils/ConstantSettings";
//import AWS from 'aws-sdk';

const PersonalInfo = (props) => {
  const {t} = useTranslation()

  // Retrieve values from localStorage
  const firstname = localStorage.getItem('firstname') || '';
  const lastname = localStorage.getItem('lastname') || '';

  // Get the first character from both firstname and lastname
  const firstnameAcronym = firstname.charAt(0);
  const lastnameAcronym = lastname.charAt(0);

  const dispatch = useDispatch()
  const [userImgFile, setUserImgFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  // const [userImgAws, setUserImgAws] = useState("")
  
  const encodedImg = `${useSelector((state)=>state.profileImage)}`
  const userImgAws = atob(`${encodedImg.substring(0,encodedImg.length-6)}`)

  //const [profileImage, setProfileImage] = useState("")

  //const dispatch = useDispatch() 

    // Initialize AWS SDK with your credentials
    // AWS.config.update({
    //   accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    //   secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    //   region: 'eu-north-1', // specify your region here
    // });

    // const s3 = new AWS.S3();

    // const generatePresignedUrl = (key, expiration, bucketName=process.env.REACT_APP_USER_PROFILE_PIC) => {
    //   // console.log(`
    //   // Bucket = ${bucketName}
    //   // key = ${key}
    //   // expiration = ${expiration}
    //   // `)
    //   const params = {
    //     Bucket: `${bucketName}`,
    //     Key: `${key}`,
    //     Expires: 3600, // expiration time in seconds
    //   };

    //   return new Promise((resolve, reject) => {
    //     s3.getSignedUrl('getObject', params, (err, url) => {
    //       if (err) {
    //         reject(err);
    //         //console.log("err=",err)

    //       } else {
    //         resolve(url);
    //         //console.log("url=",url)
    //       }
    //     });
    //   });
    // };


// Usage
    // const bucketName = process.env.REACT_APP_USER_PROFILE_PIC;
    // //console.log("env bucket", bucketName,  process.env.REACT_APP_USER_PROFILE_PIC)
    // const key = localStorage.getItem("profile_image");
    // const expiration = 3600; // expiration time in seconds -> 3 days = 259200seconds

    useEffect(()=>{
      // generatePresignedUrl(key, 3600, bucketName )
      // .then(url => {
      //   //console.log('Presigned URL:', url);
      //   setUserImgAws(url)
      //   // Now you can use the presigned URL as needed, such as for downloading the object in your ReactJS application
      // })
      // .catch(error => {
      //   console.error('Error generating presigned URL:', error);
      // }); 


      // if (userImgAws === ""){
      // fetchImageUrl()
      // }

    }, [])

    

    
  // function fetchImageKey(){
  //   axios.get(`${SERVICES.apiBaseUrl}/user/getprofileimage`, 
  //   {headers: {
  //     'Authorization': `Bearer ${localStorage.getItem('token')}`,
  //   }})
  //     .then((res)=>{
  //       console.log("image key", res.data)
  //       localStorage.setItem("profile_image", res.data?.profile_image)
        
  //     })
  //     .catch((error)=>{
  //       console.log("error occured while updating image")
  //     })
  // }

  function fetchImageUrl(){
    axios.get(`${SERVICES.apiBaseUrl}/user/getprofileimage`, 
    {headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    }})
      .then((res)=>{
        //console.log("image key", res.data)
        //localStorage.setItem("profile_image_url", res.data?.profile_image_url)
        dispatch(setProfileImage(`${btoa(`${res.data?.profile_image_url}`)}aKb9p7`))
      })
      .catch((error)=>{
        //console.log("error occured while updating image")
      })
  }
   

  

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setUserImgFile(file);
    //console.log("uploaded pfp:", file)

    const formData = new FormData();
    formData.append('profile_image', e.target.files[0]);

    //_.debounce(dispatch(setProfileImage(file)))
    //console.log("JSON.stringify(e.target.files[0])=", JSON.stringify({...file}))

    const reader = new FileReader();

    reader.onload = () => {
      setSelectedImage(reader.result);
      // base64 encoding:
      //setImg64(e.target.result)
    };

    reader.readAsDataURL(file);
    //props?.handleValue(JSON.stringify(e.target.files[0]), "profile_image");  

    axios.patch(`${SERVICES.apiBaseUrl}/user/update`, formData, 
    {headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    }})
      .then((res)=>{
        //console.log("image updated")
        //fetchImageUrl()
      })
      .catch((error)=>{
        //console.log("error occured while updating image")
      })
  };


  const [values, setValues] = useState(props?.user);
  const profile_image = localStorage.getItem("profile_image")


  useEffect(() => {
    setValues(props?.user);
  }, [props?.user]);

  return (
    <>
      <div className="ModalBodywrapper">
        <div className="pictureContainer">
          
          
          <div className="img-edit-wrapper"  >
          {/* <input
              type="file" accept=".png, .jpeg, .jpg" 
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0, cursor: 'pointer', zIndex:"1" }} 
              onChange={handleFileChange} /> */}

          <div className="img-wrapper" style={{ position: 'relative', display: 'inline-block' }}>
              
              {/* <img src={Profileimg} /> */}
          
                {selectedImage ? (
              <img src={selectedImage} alt="Selected" style={{ border: '1.5px solid black', width: '80px', height: '80px', borderRadius: '50%', margin: '0% 0% 0% 2%', background: 'white', objectFit:"contain" }} />
            ) : (<>

            {userImgAws !== "" ? <>
              <img src={userImgAws} alt="profile_image" style={{ border: '1.5px solid black', width: '80px', height: '80px', borderRadius: '50%', margin: '0% 0% 0% 2%', background: 'white', objectFit:"contain" }} />
            </> :
            <>
              <div style={{ border: '1.5px solid black',  width: '80px', height: '80px', borderRadius: '50%', margin: '0% 3% 0% 2%', background: '#ff690f', display: 'flex', justifyContent: 'center', alignItems: 'center' }} 
              //onClick={() => document.querySelector('.img-wrapper input[type=file]').click()}
              >
                <h4 className="profile-info-pic" style={{ margin: 'auto', textAlign: 'center' }} >{firstnameAcronym}{lastnameAcronym}</h4>
              </div>  
            </>
            }
        
            </>

            )}
          </div>

            {/* <span className="edit-img" >

              <EditpencilIcon />
            </span> */}

          </div>
          <div className="text-wrapper">
            <span className="name-user">{props?.name}</span>
            <span className="mail-user">{values?.email}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <InputField
              label={`${t("firstname")}`}
              id="text"
              styleclass="roundedInput"
              value={values?.first_name}
              onChange={(e) => {
                props?.handleValue(e.target.value, "first_name");
              }}
            />
          </div>
          <div className="col-md-6">
            <InputField
              label={`${t("lastname")}`}
              id="text"
              styleclass="roundedInput"
              value={values?.last_name}
              onChange={(e) => {
                props?.handleValue(e.target.value, "last_name");
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            {/* email here */}
            <InputField
              label={`${t("email")}`}
              id="text"
              styleclass="roundedInput"
              value={values?.email}
              disabledValue={true}
              onChange={(e) => {
                props?.handleValue(e.target.value, "email");
              }}
            />
          </div>
          <div className="col-md-6">
            <InputField
              label={`${t("Phone")}`}
              id="text"
              styleclass="roundedInput"
              value={values?.phone}
              onChange={(e) => {
                props?.handleValue(e.target.value, "phone");
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default PersonalInfo;

import React from "react";
import "../AnalyzeData/analyzeData.scss";
import FormSelectRounded from "../../components/common/FormSelectRounded";
import { DownloadIcon, RefreshIcon } from "../../assets/SVG/svg";
import ButtonRounded from "../../components/common/Button/ButtonRounded";
import PrimaryButton from "../../components/common/Button/PrimaryButton";
import DataNotFound from "../../assets/images/notfound.png";
import ChatInput from "../../components/common/ChatInput/ChatInput";
import { useTranslation } from "react-i18next";
import FormSelectRoundedVanilla from "../../components/common/FormSelectRounded/indexVanilla";
const GraphOnboardingResult = () => {

  const {t}= useTranslation()


  return (
    <div className="AnalyzeData-wrapper AnalyzeData-wrapper-Onboarding">
      <div className="header">
        <FormSelectRoundedVanilla text={"Select from dropdown"} />
        <FormSelectRoundedVanilla text={"File Output options"} />
        <FormSelectRoundedVanilla text={"Analyse decisions"} />
        <PrimaryButton
          buttonText={"Generate Results"}
          styleclass={" btn-common-primary generateResult"}
          hasIcon={true}
          icon={<RefreshIcon />}
        />
        <ButtonRounded
          styleclass={" btn-rounded-download btn-rounded"}
          hasIcon={true}
          disabled={true}
          buttonIcon={<DownloadIcon />}
        />
      </div>
      <div className="AnalyzeDataResult">
        <div className="AnalyzeResultInner">
          <div className="dataNotFound">
            {/* <DataNotFoundIcon /> */}
            <img src={DataNotFound} />
            <p>{t("analyse_data_by_adding")}</p>
          </div>
        </div>
        <div className="chat-input-analyze">{<ChatInput />}</div>
      </div>
    </div>
  );
};
export default GraphOnboardingResult;

import {useContext}from 'react'
import { AuthContext } from './AuthContext';
import axios from "axios";
import {useNavigate} from "react-router-dom";

export const SetAxiosConfig =()=>{
    const { clearUser } = useContext(AuthContext);
      const navigate= useNavigate()

axios.interceptors.response.use(async (response) => {
        return response
       }, (error) => {
        if (error?.response?.status === 401) {
            // console.log("first")
            clearUser();
            navigate('/login')
        }
       
        return Promise.reject(error)
       })
}

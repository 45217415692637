import React from "react";
export const ifEmail = (value) => {
  let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return value.match(validRegex) ? true : false


}

export const getCurrentDateTime = () => {
  const now = new Date();
  const formattedTime = now.toISOString();

  return formattedTime;
};
export const allowedFileTypes = [
  'text/plain', // Plain Text (.txt)
  'application/rtf', // Rich Text Format (.rtf)
  'application/msword', // Microsoft Word (.doc)
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Microsoft Word (.docx)
  'application/vnd.oasis.opendocument.text', // OpenDocument Text (.odt)
  'application/pdf', // PDF (.pdf)
  'application/vnd.ms-excel', // Microsoft Excel (.xls)
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Microsoft Excel (.xlsx)
  'application/vnd.oasis.opendocument.spreadsheet', // OpenDocument Spreadsheet (.ods)
  'text/csv', // CSV (Comma-Separated Values) (.csv)
  'image/jpeg', // JPEG (.jpeg, .jpg)
];
export const columnNames = [
  { id: 'file_name', name: 'File name', nameArabic: 'اسم الملف', readOnly: true },
  { id: 'invoice_id', name: 'Invoice Number', nameArabic: 'رقم الفاتورة' },
  { id: 'invoice_date', name: 'Invoice Date', nameArabic: 'تاريخ الفاتورة' },
  { id: 'due_date', name: 'Due Date', nameArabic: 'تاريخ الاستحقاق' },
  { id: 'net_amount', name: 'Net Amount', nameArabic: 'المبلغ الصافي' },
  { id: 'receiver_name', name: 'Receiver Name', nameArabic: 'اسم المستلم' },
  { id: 'total_amount', name: 'Total Amount', nameArabic: 'المبلغ الإجمالي' },
  { id: 'vat', name: 'Vat', nameArabic: 'ضريبة القيمة المضافة' },
  { id: 'receiver_address', name: 'Receiver Address', nameArabic: 'عنوان المستلم' },
  { id: 'invoice_type', name: 'Invoice Type', nameArabic: 'نوع الفاتورة' },
  { id: 'supplier_name', name: 'Supplier Name', nameArabic: 'اسم المورد' },
  { id: 'total_tax_amount', name: 'Total Tax Amount', nameArabic: 'مبلغ الضريبة الإجمالي' },
  { id: 'supplier_address', name: 'Supplier Address', nameArabic: 'عنوان المورد' },
  { id: 'receiver_tax_id', name: 'Receiver Tax Id', nameArabic: 'رقم ضريبة المستلم' },
  { id: 'document_type', name: 'Document Type', nameArabic: 'نوع المستند' },
];

export const processcolumnNames = [
  { id: 'file_name', name: 'File name', nameArabic: 'اسم الملف', readOnly: true },
  { id: 'validation_key', name: 'Validation', nameArabic: 'التحقق', readOnly: true },
  { id: 'remarks', name: 'Validation Check', nameArabic: 'فحص التحقق', readOnly: true },
  { id: 'invoice_id', name: 'Invoice Number', nameArabic: 'رقم الفاتورة' },
  { id: 'invoice_date', name: 'Invoice Date', nameArabic: 'تاريخ الفاتورة' },
  { id: 'due_date', name: 'Due Date', nameArabic: 'تاريخ الاستحقاق' },
  { id: 'net_amount', name: 'Net Amount', nameArabic: 'المبلغ الصافي' },
  { id: 'receiver_name', name: 'Receiver Name', nameArabic: 'اسم المستلم' },
  { id: 'total_amount', name: 'Total Amount', nameArabic: 'المبلغ الإجمالي' },
  { id: 'vat', name: 'Vat', nameArabic: 'ضريبة القيمة المضافة' },
  { id: 'receiver_address', name: 'Receiver Address', nameArabic: 'عنوان المستلم' },
  { id: 'invoice_type', name: 'Invoice Type', nameArabic: 'نوع الفاتورة' },
  { id: 'supplier_name', name: 'Supplier Name', nameArabic: 'اسم المورد' },
  { id: 'total_tax_amount', name: 'Total Tax Amount', nameArabic: 'مبلغ الضريبة الإجمالي' },
  { id: 'supplier_address', name: 'Supplier Address', nameArabic: 'عنوان المورد' },
  { id: 'receiver_tax_id', name: 'Receiver Tax Id', nameArabic: 'رقم ضريبة المستلم' },
  { id: 'document_type', name: 'Document Type', nameArabic: 'نوع المستند' },
];

export const prompts = localStorage.getItem("i18nextLng")?.toString() == "ar" ? 
[
  "ما هي ضوابط الفاتورة الضريبية؟",
  "متى يتوجب عليّ إصدار فاتورة ضريبية؟",
  "متى يمكنني المطالبة بضريبة المدخلات؟",
  "كيف ومتى يمكنني التسجيل في ضريبة القيمة المضافة؟",
  "عرّف آلية الاحتساب العكسي",
  "اشرح تأثير الديون المعدومة على الإقرارات الضريبية",
  "ما هي البنود التي تخضع لضريبة الصفر؟",
  "ما هي النفقات غير المسموح بها؟",
  "ما هي متطلبات التسجيل في ضريبة القيمة المضافة في الإمارات العربية المتحدة؟",
  "كيف يتم تطبيق ضريبة القيمة المضافة على خدمات والبضائع المصدّرة؟",
  "اشرح مفهوم الائتمان الضريبي للمدخلات في ضريبة القيمة المضافة في الإمارات.",
  "متى يكون الموعد النهائي لتقديم إقرارات ضريبة القيمة المضافة في الإمارات؟",
  "ما هي إجراءات إلغاء التسجيل في ضريبة القيمة المضافة في الإمارات؟",
  "هل يمكنك توضيح كيفية معاملة ضريبة القيمة المضافة على التوريدات المعفاة؟",
  "كيف يتم تطبيق ضريبة القيمة المضافة على الخدمات الرقمية في دولة الإمارات؟",
  "ما هي عقوبات عدم الالتزام بأنظمة ضريبة القيمة المضافة؟ في إيرادات الخدمات التي تقدمها شركتي؟"
] : [
  'What is the tax invoice criteria?',
  'When do I raise a tax invoice?',
  'When can I claim input tax?',
  'How and when do I register for VAT?',
  'Define Reverse Charge Mechanism',
  'Explain bad debt adjustment on tax returns',
  'What items are zero rated?',
  'What are disallowable expenses?',
  "What are the VAT registration requirements in the UAE?",
  "How does VAT apply to exported goods and services?",
  "Explain the concept of Input Tax Credit in UAE VAT.",
  "Can you clarify the treatment of VAT on exempt supplies?",
  "How does VAT apply to digital services in the UAE?",
  "What are the penalties for non-compliance with VAT regulations?",

];
//localStorage.getItem("i18nextLng").toString()


// accounting prompts
export const ifrs_prompts = localStorage.getItem("i18nextLng")?.toString() == "ar" ? 
[
  "كيف تتم المعالجة المحاسبية للمخصصات؟",
  "ما هو مفهوم الاستمرارية؟",
  "ما هي القيمة العادلة؟",
  "هل يتم استهلاك الأراضي؟",
  "كيف تتم المعالجة المحاسبية لإيجار المكتب / المخزن؟",
  "كيف تتم المعالجة المحاسبية للديون على الميزانية؟",
  "ما هي خسائر الإئتمان المتوقعة وكيف تتم المعالجة المحاسبية لها؟",
  "ما هو الدخل الشامل الآخر وماذا يتضمّن؟",
  "كيف يمكنني الاعتراف بإيرادات الخدمات التي تقدمها شركتي؟",

  "ما هي المعالجة المحاسبية لشراء عقار أو سيارة لأغراض العمل؟",
  "كيف يختلف الإطار التنظيمي المحاسبي في دولة الإمارات العربية المتحدة عن معايير المحاسبة الدولية؟",
  "هل يمكنك توضيح النقاط الرئيسية لقوانين الضرائب وأسس المحاسبة الخاصة بدولة الإمارات العربية المتحدة؟",

] :
[
  'How to account for provisions?', 
  'What is the concept of going concern?',
  'What is fair valuation?', 
  'Do we need to depreciate land?',
  'How do I account a lease for my office / store ?',
  'How do you account for debt on your balance sheet?',
  'What is expected credit loss and how to account for it?',
  'What is other comprehensive income and what does it have?',
  'How do I recognise revenue for services my business provides?',

  "How to account for a car or property purchased for business purposes?",
  "How does the UAE's accounting regulatory framework differ from international accounting standards?",
  "Can you elaborate on the key tax regulations and accounting principles specific to the UAE?"

];

export const ksa_prompts = localStorage.getItem("i18nextLng")?.toString() == "ar" ?  [
  "كيف يتم التسجيل في ضريبة القيمة المضافة في السعودية؟",
  "ما هي الخطوات التي يجب علي اتخاذها لتقديم الإقرار الضريبي؟",
  "ما هي وتيرة تقديم إقرارات ضريبة القيمة المضافة، ومتى تكون المواعيد النهائية؟",
  "ما هي المنافع الضريبية المقدّمة للشركات الصغيرة والمتوسطة، بالأخص للشركات في القطاعات الإبداعية؟",
  "ما هي التداعيات الضريبية للشركات الناشئة المنخرطة في المدفوعات والخدمات عبرالحدود؟",
  "كيف تسترد الشركات الناشئة السعودية ضريبة القيمة المضافة على نفقات الأعمال؟",
  "كيف يتم احتساب وسداد الزكاة في الشركات الصغيرة والمتوسطة، ومن يتوجب عليه سدادها؟",
  "ما هي التزامات ضريبة القيمة المضافة المحددة لشركات التجارة الإلكترونية الناشئة؟",
  "ما هي القواعد التوجيهية للضريبة المقتطعة في الشركات الناشئة؟",
  "ما هي العقوبات الضريبية المفروضة على الشركات الصغيرة والمتوسطة غير الملتزمة، وكيف يتم تجنبها؟",
  "هل يوجد إعفاءات أو تخفيضات على ضريبة القيمة المضافة للشركات الصغيرة والمتوسطة في قطاعات معينة؟",
  "هل هنالك اقتطاعات أو خصومات ضريبية للشركات الصغيرة والمتوسطة المنخرطة في أنشطة البحث والتطوير؟",
  "ما هي استراتيجيات الضريبة التي تسطيع الشركات الناشئة اعتمادها لتحسين موقفها الضريبي؟",
  "كيف يمكن للشركات الناشئة السعودية الاستفادة من المزايا الضريبية للمناطق الحرّة؟",
  "أين يمكن للشركات الصغيرة والمتوسطة الحصول على تحديثات القوانين الضريبية والبقاء على إطّلاع عليها؟",
  "هل هنالك أي ضرائب خاصة بقطّاعات معينة للشركات الناشئة؟",
  "كيف تطبّق ضريبة الشركات على الشركات الصغيرة والمتوسطة، بما في ذلك الشركات المملوكة من جهات أجنبية؟"
] : [
  "How do you register for VAT in KSA?",
  "What steps do I take to file a tax return?",
  "How often must startups submit VAT returns, and when are the deadlines?",
  "What tax benefits are offered to SMEs, particularly those in innovation-driven sectors?",
  "What are the tax implications for startups engaged in cross-border payments and services?",
  "How do Saudi startups reclaim VAT on business expenses?",
  "How do SMEs calculate and pay Zakat, and who must pay?",
  "What are the specific VAT obligations for e-commerce startups?",
  "What are the withholding tax guidelines for startups?",
  "What are the tax penalties for SME non-compliance, and how to avoid them?",
  "Are there VAT exemptions or reductions for SMEs in certain sectors?",
  "Are there tax credits or deductions for SMEs engaging in R&D activities?",
  "What tax strategies can startups adopt to optimize their tax position?",
  "How can Saudi startups benefit from free zones' tax advantages?",
  "Where can SMEs get tax law updates and stay informed?",
  "Are there any sector-specific taxes for startups?",
  "How is corporate tax applied to SMEs, including foreign-owned ones?"
]


export const formatText = (text) => {
  const lines = text.split('\n');
  const formattedText = [];
  let currentHeading = null;
  let currentSubHeading = null;
  let currentList = null;
  let listItems = [];

  let keyCounter = 0; // Initialize a counter for unique keys

  for (const line of lines) {
    if (line.startsWith('## ')) {
      // Second-level heading
      currentHeading = <h2 key={`heading_${keyCounter++}`}>{line.substring(3)}</h2>;
      formattedText.push(currentHeading);
      currentSubHeading = null;
      currentList = null;
    } else if (line.startsWith('### ')) {
      // Third-level heading
      currentSubHeading = <h3 key={`subheading_${keyCounter++}`}>{line.substring(4)}</h3>;
      formattedText.push(currentSubHeading);

      // If there are list items, add them before the new subheading
      if (listItems.length > 0) {
        if (currentList) {
          currentList = React.cloneElement(currentList, {
            children: listItems,
          });
          formattedText.push(currentList);
        } else {
          currentList = <ul key={`list_${keyCounter++}`}>{listItems}</ul>;
          formattedText.push(currentList);
        }
        listItems = [];
      }
    } else if (/^\s*-/.test(line)) {
      // Check if the line starts with a hyphen with optional spaces
      const trimmedLine = line.trim(); // Remove leading and trailing spaces
      if (trimmedLine.startsWith('- **')) {
        // List item with bold text
        const parts = trimmedLine.split('**');
        const boldText = parts[1];
        const regularText = parts[2] ? parts[2].trim() : '';
        if (!currentList) {
          currentList = (
            <ul key={`list_${keyCounter++}`}>
              <li>
                <strong>{boldText}</strong> {regularText}
              </li>
            </ul>
          );
        } else {
          const newChildren = React.Children.toArray(currentList.props.children);
          newChildren.push(
            <li key={`listitem_${keyCounter++}`}>
              <strong>{boldText}</strong> {regularText}
            </li>
          );
          currentList = React.cloneElement(currentList, {
            children: newChildren,
          });
        }
        listItems.push(currentList);
        currentList = null;
      } else if (trimmedLine.startsWith('- ')) {
        // Regular list item
        if (!currentList) {
          currentList = (
            <ul key={`list_${keyCounter++}`}>
              <li>{trimmedLine.substring(2)}</li>
            </ul>
          );
        } else {
          const newChildren = React.Children.toArray(currentList.props.children);
          newChildren.push(<li key={`listitem_${keyCounter++}`}>{trimmedLine.substring(2)}</li>);
          currentList = React.cloneElement(currentList, {
            children: newChildren,
          });
        }
        listItems.push(currentList);
        currentList = null;
      }
    } else {
      // Regular text
      if (listItems.length > 0) {
        if (currentList) {
          currentList = React.cloneElement(currentList, {
            children: listItems,
          });
          formattedText.push(currentList);
        } else {
          currentList = <ul key={`list_${keyCounter++}`}>{listItems}</ul>;
          formattedText.push(currentList);
        }
        listItems = [];
      }

      if (currentList) {
        currentList = null;
      }

      // Handle regular text with newlines
      const paragraphs = line.split('\n\n');
      for (const paragraph of paragraphs) {
        formattedText.push(<p key={`paragraph_${keyCounter++}`}>{paragraph}</p>);
      }
    }
  }

  return formattedText;
};






import React ,{useState} from "react";
import "./feedbackcontent.scss";
import Form from "react-bootstrap/Form";
import PrimaryButton from "../common/Button/PrimaryButton";
import { SendIconDark } from "../../assets/SVG/svg";
import { useTranslation } from "react-i18next";
const FeedbackContent = (props) => {
  const {t} = useTranslation()
  const suggestion=[
   {id:1,val: "Easy to understand"},
   {id:2,val: "Satisfied with chatbot"},
   {id:3,val: "Tell us what you think"}
  ]
  const [value,setValue]=useState('')
  return (
    <>
      <div className="feedbackContentWrapper">
        {/* <div className="suggetion-box-wrapper">{
          suggestion?.map((sug)=>(
          <div className="suggetion-box" key={sug?.id}> {sug.val}</div>
          ))}
        </div> */}
        <Form.Group
          className="position-relative mt-auto form-control-custom-wrapper"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Control
          
            type="text"
            autoComplete="off"
            placeholder={`${t("feedback_placeholder")}`}
            className="form-control-custom"
            value={value}
            onChange={(e)=>setValue(e.target.value)}
            onKeyDown={(event) => {
              if (value !== "") {
                if (event.key === "Enter") {
                  setValue("");
                  props?.call(value,props?.type)
                                }
              }
            }}
          />
          <PrimaryButton
            buttonText={<SendIconDark />}
            styleclass={`bg-dark ButtonInput ButtonInputFeedback`}
            icon={undefined}
            hasIcon={false}
            size={undefined}
            type="submit"
            onClick={()=>{ if (value !== "") {
              setValue("");
              props?.call(value,props?.type)}}}
          />
        </Form.Group>
      </div>
    </>
  );
};
export default FeedbackContent;

import React, { useState, useEffect } from "react";
import "./aichat.scss";
import {
  CheckedTrue,
  Dots,
  EditIcon,
  FilterIcon,
  ModalCloseIcon,
  Star,
  StarIcon,
  TrashIcon,
} from "../../assets/SVG/svg";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useTracking } from "react-tracking";
import { current } from "@reduxjs/toolkit";

import { UseDispatch, useDispatch, useSelector } from "react-redux";
import { disableAllChatMode, enableChatMode } from "../../ReduxSlices/ChatModeSlice";
import { useTranslation } from "react-i18next";
import { setKillRequest } from "../../ReduxSlices/RequestKillerSlice";
import { Tooltip } from "@mui/material";

const AIChat = (props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const navigate = useNavigate();
  const [enable, setEnable] = useState();
  const [data, setData] = useState(props?.data);
  const { id, cid } = useParams();

  const [oldHistoryItem, setOldHistoryItem] = useState({ display_name: "" })
  var newHistoryItem = { display_name: "" }

  //const [deletedItem, setDeletedItem] = useState({id:""})
  var deletedItem = { display_name: "" }
  var currentItem = { display_name: "" }

  const { trackEvent } = useTracking()


  useEffect(() => {
    setData(props?.data);
  }, [props?.data]);
  const patchBatch = useMutation(props?.editApi);
  const dellBatch = useMutation(props?.dellApi);
  const editNameCall = (fav, ids) => {

    const itemIndex = data.findIndex((item) => `${item.id}` === `${enable}`);
    newHistoryItem = data[itemIndex]

    trackEvent({ event_type: "rename", event_name: `A History Item was Renamed in ${HandleActiveTabName(props?.activeTab)} Module's History`, event_data: { history_item_id: `${oldHistoryItem?.id}`, old_item_name: `${oldHistoryItem?.display_name}`, new_item_name: `${newHistoryItem?.display_name}` } })

    patchBatch.mutate(
      {
        val: {
          name: !fav ? data[itemIndex]?.display_name : undefined,
          favourite: fav,
        },
        profile: localStorage.getItem("profileid"),
        id: ids ?? enable,
      },

      {
        onSuccess: (data, variables, context) => {
          props?.refetch();
          setEnable("none");
        },
        onError: (error, variables, context) => {
          setData(props?.data);

        },
      }
    );
  };
  const dellBatchCall = (id) => {
    dellBatch.mutate(
      {
        profile: localStorage.getItem("profileid"),
        id: id,
      },

      {
        onSuccess: (data, variables, context) => {
          trackEvent({ event_type: "delete", event_name: `A History Item was deleted from ${HandleActiveTabName(props?.activeTab)} Module's History`, event_data: { history_item_id: `${deletedItem?.id}`, deleted_item_name: `${deletedItem?.display_name}` } })
          props?.refetch();
          if (cid == id) {
            if (window.location.pathname.includes("invoice")) {
              navigate("/invoice");
            } else if (window.location.pathname.includes("analyse")) {
              navigate("/analyse");
            } else {
              navigate("/");
            }
          }
        },
        onError: (error, variables, context) => {
          trackEvent({
            event_type: "error",
            event_name: "Failed to delete history item",
            event_data: {
              error: `${error}`,
              history_item_id: `${deletedItem?.id}`,
              history_item_name: `${deletedItem?.display_name}`
            }
          })
        },
      }
    );
  };
  const HandleActiveTabName = (tab) => {
    if (tab === "AiChat") {
      return "Advise"
    } else if (tab === "analyze") {
      return "Analyse"
    } else if (tab === "UploadInvoice") {
      return "Process"
    }
    else return `${tab}`
  }
  const RenameHandler = (e, id) => {
    if (enable && enable !== id) {
      // Reset the previous item (remove editing state)
      const prevParentElement = document.querySelector(`.form-control-wrapper [value='${data.find(item => item.id === enable)?.display_name}']`)?.closest(".form-control-wrapper");
      if (prevParentElement) {
        const prevInputElement = prevParentElement.querySelector(".form-control-custom");
        const prevIconHolder = prevParentElement.querySelector(".icon-holder");
  
        if (prevInputElement && prevIconHolder) {
          prevInputElement.classList.remove("bg-white");
          
          prevIconHolder.style.display = "none";
        }
      }
    }
  
    //console.log('RenameHandler triggered for id:', id);
  
    setEnable(id); // Set the currently active input for renaming
  
    const parentElement = e.target.closest(".form-control-wrapper");
    if (parentElement) {
      const InputElement = parentElement.querySelector(".form-control-custom");
      const iconHolder = parentElement.querySelector(".icon-holder");
  
      if (InputElement && iconHolder) {
        //console.log('Enabling input and showing icons');
        
        InputElement.focus();
        InputElement.classList.add("bg-white");
        iconHolder.style.display = "flex";
      }
    }
  };
  

  const RenmedSuccessHandler = (e) => {
    //console.log('RenmedSuccessHandler triggered');
  
    const parentElement = e.target.closest(".form-control-wrapper");
    if (parentElement) {
        const InputElement = parentElement.querySelector(".form-control-custom");
        const iconHolder = parentElement.querySelector(".icon-holder");
  
        if (InputElement && iconHolder) {
            //console.log('Disabling input and hiding icons');
            InputElement.classList.remove("bg-white");
            // InputElement.setAttribute("disabled", "");
            iconHolder.style.display = "none";
            setEnable("none"); // Reset the enable state to allow for the next double-click
        }
    }
  };

  
  const handleInputChange = (e, id) => {
    const newValue = e.target.value;

    //console.log("renamed value = ", newValue)

    setData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, display_name: newValue } : item
      )
    );
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initial check for mobile view

  useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 768); // Mobile view breakpoint (can be adjusted)
      };

      window.addEventListener('resize', handleResize);

      // Cleanup function to remove the event listener
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);
  
  const requestActive = useSelector((state) => state.requestActive)

  return (
    <div className="AiChats">
      <div className="AiChat-header">

        <span>{(props?.filter == "All" ? `${t("all")}` : `${t("favourites")}`) + " " + props?.text}</span>
        <Dropdown>
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            className="filterbtn bg-transparent border-0"
            drop="down-centered"
          >
            <FilterIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu drop="down-centered">
            <Dropdown.Item onClick={() => {
              trackEvent({ event_type: "click", event_name: `Filter was changed in ${HandleActiveTabName(props?.activeTab)} Module's History`, event_data: { filter_name: `${props?.filter === "All" ? "Favourite" : "All"}` } })
              props?.filterSetter(props?.filter === "All" ? "Favourite" : "All")
            }}>
              <StarIcon />
              {props?.filter === 'All' ? `${t("favourites")}` : `${t("all")}`}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {data?.map((item) => (
        <div 
          className="form-control-wrapper" 
          style={{ cursor: "pointer", display: "flex", justifyContent: "start" }} 
          key={item?.id}
          onClick={() => {
            currentItem = { ...item };
            trackEvent({ event_type: "click", event_name: `A history item in ${HandleActiveTabName(props?.activeTab)} Module was accessed`, event_data: { ...currentItem } })
          }}
        >
          <Tooltip

            title={`${item?.display_name}`}
            arrow
            placement={isMobile ? "bottom" : (localStorage.getItem('i18nextLng') === "ar" ? "left" : "right")}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 0],
                    },
                  },
                ],
              },
            }}
            PopperProps={{
                disablePortal: false,
                modifiers: [
                    {
                        name: 'preventOverflow',
                        options: {
                            altBoundary: true,
                            tether: false,
                        },
                    },
                ],
            }}
          >
            <div
              className={`${cid == item?.id ? "selected-item" : ""}`}
              style={{
                borderRadius: "8px",
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                className="form-control form-control-custom cursor-pointer-function"
                type="text"
                value={item?.display_name}
                readOnly={`${item?.id}` === `${enable}` ? false : true}
                
                onClick={() => {
                  if (requestActive === true) {
                    dispatch(setKillRequest("chatcall"))
                  } else {
                    dispatch(setKillRequest("none"))
                  }
                  localStorage.setItem('mode', item?.mode)
                  dispatch(enableChatMode(`${item?.mode}`))

                  if (props?.activeTab === "AiChat") {
                    navigate(`/chat/${item?.profile}/${item?.id}`);
                  } else if (props?.activeTab === "analyze") {
                    navigate(`/analyse/${item?.user_profile}/${item?.id}`);
                  } else {
                    navigate(`/invoice/${item?.user_profile}/${item?.id}`);
                  }
                }}
                onChange={(e) => handleInputChange(e, item?.id)}
                onDoubleClick={(e) => { 
                  RenameHandler(e, item?.id);
                  setOldHistoryItem({ ...data.find((historyItem) => historyItem.id === item?.id) });
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                      RenmedSuccessHandler(event);
                      editNameCall();
                  }
                }}
              />

              <span className="dropdown-wrapper" style={{ cursor: "pointer" }}>
                <div
                  className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "" : "dropdown-menu-en"}`}
                >
                  <Dropdown autoClose="inside">
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="bg-transparent border-0"
                      drop="down-centered"
                    >
                      <Dots />
                    </Dropdown.Toggle>

                    <Dropdown.Menu drop="down-centered">
                      <Dropdown.Item onClick={() => { editNameCall(item?.favourite ? false : true, item?.id); }}>
                        <Star />
                        <span>{item?.favourite ? `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? t("remove_favourite") : "Remove from favourites"}` : `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? t("mark_favourite") : "Mark as favourite"}`}</span>
                      </Dropdown.Item>
                      <Dropdown.Item id="rename" 
                      onClick={(e) => { RenameHandler(e, item?.id);
                      setOldHistoryItem({ ...data.find((historyItem) => historyItem.id === item?.id) });
                      }}
                      >
                        <EditIcon />
                        {t("rename")}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => { deletedItem = { ...data.find((historyItem) => historyItem.id === item?.id) }; dellBatchCall(item?.id); }}>
                        <TrashIcon />
                        {t("delete")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

              </span>

              <span className={`icon-holder ${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "icon-header-ar" : ""}`}>
                <span className="Renamed" onClick={(e) => { RenmedSuccessHandler(e); editNameCall(); }}>
                  <CheckedTrue />
                </span>
                <span className="Close" onClick={(e) => { RenmedSuccessHandler(e); setData(props?.data); }}>
                  <ModalCloseIcon />
                </span>
              </span>
            </div>
          </Tooltip>
        </div>
      ))}

    </div>
  );
};
export default AIChat;

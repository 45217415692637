import React, { useEffect, useState } from "react";
import InputPassword from "../common/Input/InputPassword";
import { useTranslation } from "react-i18next";
const PrivacySecurity = ({ handleValue, values, errorSetter }) => {
  const [newPassword, setNewPassword] = useState();
  const [error, setError] = useState(false);
  const {t} = useTranslation()

  useEffect(()=>{
    // console.log("values.password = ", values.password)
    // console.log("newPassword = ", newPassword)

    if(values.password?.trim() == "" || newPassword?.trim() == "" || values.confirm_password?.trim() == ""){

      setError(false)
      errorSetter(false)
      setNewPassword(undefined)
      handleValue(undefined, "password");
      handleValue(undefined, "confirm_password");

    }
    else if ( (values.password?.trim() !== "" || newPassword?.trim() !== "") && values.password !== newPassword){
      setError(`${t("error_password_mismatch")}`);
      errorSetter(true, t("error_password_mismatch"));
    }
    else{
      setError(false)
      errorSetter(false)
    }
  },[newPassword, values.password, values.confirm_password])

  return (
    <div className="ModalBodywrapper">
      <div className="row">
        <div className="col-md-12">
          <InputPassword
            autoComplete="off"
            label={`${t("new_password")}`}
            hasForgotpassword={false}
            value={values.password?.trim() != "" ? values.password : ""}
            styleclass="roundedInput password-wrapper"
            onChange={(e) => {
              handleValue(e.target.value, "password");
            
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <InputPassword
            autoComplete={false}
            label={`${t("confirm_password")}`}
            hasForgotpassword={false}
            //   value={values.password}
            styleclass="roundedInput password-wrapper"
            //   error={error.password}
            value={newPassword?.trim() != "" ? newPassword : ""}
            error={error}
            onChange={(e) => {
              setNewPassword(e.target.value);
              handleValue(e.target.value, "confirm_password");
              
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default PrivacySecurity;

import React from "react";

const InputField = ({
  label,
  type,
  id,
  styleclass,
  placeholder,
  onChange,
  value,
  error,
  disabledValue
}) => {
  return (
    <div className={`mb-3 ${error ? "has-error" : ""}`}>
      <label className="form-label">{label}</label>
      <input
        className={`form-control ${error ? "is-invalid" : ""} ${styleclass}`}
        type={type}
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        disabled={disabledValue}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default InputField;

import { create } from "@mui/material/styles/createTransitions";
import { createSlice } from "@reduxjs/toolkit";

// const initialState: CounterState = {value:0} // annotations are only for TypeScript
// const initialValue = {
//     remaining_credits: "0"
// }
const apiCountSlice = createSlice({
    name:'apiCount',
    initialState: "0",
    reducers: {
        setApiCount: (state, action) => {
            return action.payload;
        }
    }
})

export const {setApiCount} = apiCountSlice.actions;
export default apiCountSlice.reducer